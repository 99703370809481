import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  domainSquating,
  takeDown,
  domainSquatingTotal,
  domainSquatingExcel,
  domainSquatingAction,
  domainSquatingDomain,
} from "../../actions/look-alike";
import moment from "moment";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import { Tabs, Tab } from "react-bootstrap";
import { IMAGE_URL } from "../../utils/constant";
// import TinyMCE from "react-tinymce";
import { toastr } from "react-redux-toastr";
import AccessPage from "../Shared/accessPage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Domains = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  const auth = useSelector((state) => state?.auth?.user);

  let domain = client?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  if (domain?.charAt(domain.length - 1) == "/") {
    domain = [domain.substr(0, domain.length - 1)];
    // domain.unshift("all");
  } else {
    domain = [domain];
    // domain.unshift("all");
  }
  if (client?.domains && client.domains.length > 0) {
    domain = client.domains;
    // domain.unshift("all");
  }

  const dispatch = useDispatch();

  const [subject, setSubject] = useState("");
  const [stolenLocation, setStolenLocation] = useState("");
  const [contenSource, setContenSource] = useState("");
  const [infringingIp, setInfringingIp] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [rowId, setRowId] = useState("");
  const [domainFilter, setDomainFilter] = useState("all");

  const dnsTableRef = useRef(null);
  const ownerTableRef = useRef(null);

  const [filterData, setfilterData] = useState("all");
  const [isModal, setModal] = useState("");
  const [isModalDns, setModalDns] = useState("");
  const [isTakeDownModal, setTakeDownModal] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedRow, setSelectedId] = useState([]);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [category, setCategory] = useState("all");
  const [isModalTable, setModalTable] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState("");
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  const [subdomain, setSubdomain] = useState("all");

  const domains = useSelector(
    (state) => state?.lookAlike?.domainSquating?.data?.data
  );
  const totalCount = useSelector(
    (state) => state?.lookAlike?.domainSquating?.data?.totalLookalikeDomains
  );
  const scanCompleted = useSelector((state) => state?.lookAlike?.scanCompleted);
  const subDomainList = useSelector(
    (state) => state?.lookAlike?.subDomainList?.data?.domains
  );

  const totalLookLikeCount = useSelector(
    (state) => state?.lookAlike?.lookaliketotal?.data
  );

  const [totalItems, setTotalItems] = useState(0);

  const scanDetails = useSelector(
    (state) => state?.lookAlike?.lookalikeGraph?.data
  );

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        lookalikeDomainIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      domainSquatingAction(
        id,
        dispatch,
        data,
        page,
        category,
        subdomain,
        filterData
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      lookalikeDomainIds: selectedIds == 0 ? ids : selectedIds,
    };
    domainSquatingAction(
      id,
      dispatch,
      data,
      page,
      category,
      subdomain,
      filterData
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      lookalikeDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    domainSquatingAction(
      id,
      dispatch,
      data,
      page,
      category,
      subdomain,
      filterData
    );
    setActionNote("");
    setModalTable("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModalTable("Form Wizard modal");
  };

  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails[details]);
  }

  useEffect(() => {
    domainSquating(id, dispatch, page, category, subdomain, filterData);
  }, [id, dispatch, page, category, subdomain, filterData]);

  useEffect(() => {
    setTotalItems(totalCount);
  }, [totalCount]);

  useEffect(() => {
    domainSquatingTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    domainSquatingExcel(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    domainSquatingDomain(id, dispatch);
  }, [id, dispatch]);

  let mapData = domains;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    // const firstPageIndex = (page - 1) * PageSize;
    // const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData;
  }
  console.log(currentTableData, "current");

  const handleEditorChange = (even, editor) => {
    const data = editor.getData();
    setEditorContent(data); // Update the state with the new content
  };

  const takeDownValue = (res) => {
    let ip;
    res?.dnsDetails?.map((row) => {
      return Object.keys(row || {}).map((key) => {
        let record = row[key];
        if (record && record.length == 0) return false;
        if (key !== "dns_a") return false;
        record?.map((val) => {
          ip = val;
        });
      });
    });
    let originalWebsite = client.website;
    let domainName = res.lookalike;
    setStolenLocation(originalWebsite);
    setContenSource(domainName);
    setInfringingIp(ip);
    setRowId(res._id);
    setTakeDownModal(true);
  };

  const submitTakeDownValue = () => {
    if (subject == "") {
      toastr.error("Error", "Subject cannot be empty.");
      return false;
    }
    if (stolenLocation == "") {
      toastr.error("Error", "Stolen location cannot be empty.");
      return false;
    }
    if (contenSource == "") {
      toastr.error("Error", "Content source cannot be empty.");
      return false;
    }
    if (infringingIp == "") {
      toastr.error("Error", "Infringin IP cannot be empty.");
      return false;
    }
    if (editorContent == "") {
      toastr.error("Error", "Editor content cannot be empty.");
      return false;
    }

    setTakeDownModal(false);
    takeDown(
      {
        subject,
        stolenLocation,
        contenSource,
        infringingIp,
        editorContent,
        rowId,
        id,
        page: "domain"
      },
      dispatch
    );
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/organisations/${id}/domainsquatting/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Look-alike domains.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const dnsKeyName = (val) => {
    if (val == "dns_a") return "A Record";
    else if (val == "dns_ns") return "NS Record";
    else if (val == "dns_mx") return "MX Record";
    else if (val == "dns_aaa") return "AAA Record";
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Look-alike Domains</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li className="breadcrumb-item active" aria-current="page">
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Look-alike Domains
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalLookLikeCount?.individualRating}</b>
                </span>
              </nav>
            </div>

            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {client?.subscriptionType != "trial" && domains?.length !== 0 && (
                <span>
                  <a
                    onClick={() => downloadFile()}
                    style={{
                      float: "right",

                      fontWeight: "bold",
                    }}
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o mr-1"
                      style={{}}
                    ></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        {/* <div className="row clearfix">
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body">
              <div className="d-flex align-items-center">
                <div
                  className="icon-in-bg bg-indigo text-white rounded-circle"
                  style={{ background: "#086CD9" }}
                >
                  <i className="fa fa-database"></i>
                </div>
                <div className="ml-4">
                  <span>Total Domains</span>
                  <h4 className="mb-0 font-weight-medium">
                    {" "}
                    {domains?.length || 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body">
              <div className="d-flex align-items-center">
                <div
                  className="icon-in-bg  text-white rounded-circle"
                  style={{ background: "#DC2B2B" }}
                >
                  <i className="fa fa-exclamation-triangle"></i>
                </div>
                <div className="ml-4">
                  <span>Issues Found</span>
                  <h4 className="mb-0 font-weight-medium">
                    {" "}
                    {domains?.length || 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body">
              <div className="d-flex align-items-center">
                <div
                  className="icon-in-bg  text-white rounded-circle"
                  style={{ background: "#D5691B" }}
                >
                  <i className="fa fa-exchange"></i>
                </div>
                <div className="ml-4">
                  <span>Changes in last 30 days</span>
                  <h4 className="mb-0 font-weight-medium">
                    {" "}
                    {registerInLast30?.length || 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body">
              <div className="d-flex align-items-center">
                <div className="icon-in-bg bg-pink text-white rounded-circle">
                  <i className="fa fa-exchange"></i>
                </div>
                <div className="ml-4">
                  <span>Changes in last 60 days</span>
                  <h4 className="mb-0 font-weight-medium">
                    {" "}
                    {registerInLast60?.length || 0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div className="row clearfix">
          <div className="col-md-4">
            <div className="card white-box-dashboard">
              <div className="body" style={{}}>
                <div className="row text-center">
                  <div className="col-12">
                    <div className="card-box mr-0">
                      <div className="d-flex justify-content-between">
                        <i className="font-50 fa fa-window-maximize icon-dashboard"></i>
                        <h4 className="font-44 total-h4">
                          {totalLookLikeCount?.totalLookalikeDomains || 0}
                        </h4>
                      </div>
                      <label className="mb-0 text-right">
                        Total Domains
                      </label>
                    </div>


                  </div>

                  <div className="col-12">
                    <div className="card-box mr-0">
                      <div className="d-flex justify-content-between">
                        <i className="font-50 fa fa-clipboard-list icon-dashboard"></i>
                        <h4 className="font-44 total-h4">
                          {totalLookLikeCount?.totalLookalikeDomainsRegisteredInLast30Days ||
                            0}
                        </h4>
                      </div>
                      <label className="mb-0 text-right">
                        Registered in last 30 days
                      </label>
                    </div>
                  </div>
                </div>
                {/* <hr /> */}
                <div className=" card-box mr-0">
                  <div className="form-group list-with-number">
                    <label
                      className="d-block"
                      style={{ cursor: "pointer" }}
                      onClick={() => setfilterData("active")}
                    >
                      Active Domains
                      <span className="float-right">
                        {(totalLookLikeCount?.totalLookalikeDomains || 0)?.toFixed(
                          0
                        )}
                      </span>
                    </label>
                    <div className="progress progress-xxs">
                      <div
                        className="progress-bar progress-bar-success"
                        role="progressbar"
                        aria-valuenow={(
                          totalLookLikeCount?.totalLookalikeDomains || 0
                        )?.toFixed(0)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width:
                            (
                              totalLookLikeCount?.totalLookalikeDomains || 0
                            )?.toFixed(0) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="form-group list-with-number">
                    <label
                      className="d-block"
                      style={{ cursor: "pointer" }}
                      onClick={() => setfilterData("registered-in-last-30-days")}
                    >
                      Registered in last 30 days
                      <span className="float-right">
                        {(
                          totalLookLikeCount?.totalLookalikeDomainsRegisteredInLast30Days ||
                          0
                        )?.toFixed(0)}
                      </span>
                    </label>
                    <div className="progress progress-xxs">
                      <div
                        className="progress-bar progress-bar-warning"
                        role="progressbar"
                        aria-valuenow={(
                          totalLookLikeCount?.totalLookalikeDomainsRegisteredInLast30Days ||
                          0
                        )?.toFixed(0)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width:
                            (
                              totalLookLikeCount?.totalLookalikeDomainsRegisteredInLast30Days ||
                              0
                            )?.toFixed(0) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="form-group list-with-number">
                    <label
                      className="d-block"
                      style={{ cursor: "pointer" }}
                      onClick={() => setfilterData("60days")}
                    >
                      Registered in last 60 days{" "}
                      <span className="float-right">
                        {(
                          totalLookLikeCount?.totalLookalikeDomainsRegisteredInLast60Days ||
                          0
                        )?.toFixed(0)}
                      </span>
                    </label>
                    <div className="progress progress-xxs">
                      <div
                        className="progress-bar progress-bar-info"
                        role="progressbar"
                        aria-valuenow={(
                          totalLookLikeCount?.totalLookalikeDomainsRegisteredInLast60Days ||
                          0
                        )?.toFixed(0)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width:
                            (
                              totalLookLikeCount?.totalLookalikeDomainsRegisteredInLast60Days ||
                              0
                            )?.toFixed(0) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  {client?.subscriptionType == "trial" && (
                    <div className="card border-warning">
                      <TrialComponent />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card  white-box-dashboard">
              <div className="body" style={{ minHeight: "400px" }}>
                <h6>Previous scan details</h6>

                <div id="University_Survey" className="flot-chart">
                  {/* <SurveyChart /> */}
                  {scanDetails && (
                    <BasicLineColumn
                      dates={scanDetails?.dates}
                      ipArray={ipArray}
                      color={["#DD3731"]}
                      yAxis="Similar Domains found"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <br />
            <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                    }`}
                  onClick={() => {
                    setCategory("all");
                    setPage(1);
                    setSubdomain("all");
                    setfilterData("all");
                    setSelectedIds([]);
                  }}
                >
                  Current
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                    }`}
                  onClick={() => {
                    setCategory("risk-accepted");
                    setPage(1);
                    setSubdomain("all");
                    setfilterData("all");
                    setSelectedIds([]);
                  }}
                >
                  Risk Accepted
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                    }`}
                  onClick={() => {
                    setCategory("false-positive");
                    setPage(1);
                    setSubdomain("all");
                    setfilterData("all");
                    setSelectedIds([]);
                  }}
                >
                  False Positive
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                    }`}
                  onClick={() => {
                    setCategory("remidiated");
                    setPage(1);
                    setSubdomain("all");
                    setfilterData("all");
                    setSelectedIds([]);
                  }}
                >
                  Remediated
                </a>
              </li>
            </ul>
            <div className="card">
              <div className="body">
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="registered-in-last-30-days">
                        Registered in 30 days
                      </option>
                      <option value="registered-in-last-60-days">
                        Registered in 60 days
                      </option>
                    </select>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={subdomain}
                      onChange={(e) => setSubdomain(e.target.value)}
                    >
                      <option value="all">All</option>
                      {subDomainList?.map((row, index) => {
                        return <option value={row}>{row?.toUpperCase()}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze("", event.target.value);
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={selectedIds == 0 ? false : multiCheckTrue}
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                      <th>#</th>
                      <th>Original Domain</th>
                      <th>Similar Domain</th>
                      <th>Type</th>
                      <th>Domain Registered on</th>
                      <th>Domain Expiry on</th>
                      <th>IP Address</th>
                      <th>MX Record</th>
                      <th>Registrar</th>
                      <th>Actions</th>
                      <th>Owner Detail</th>
                      <th>Last updated on</th>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {client?.subscriptionType == "trial" ? (
                      <tr>
                        <td colSpan={7}>
                          <NoData />
                        </td>
                      </tr>
                    ) : (
                      currentTableData?.map((res, index) => {
                        let mxRecord = false;
                        {
                          res?.dnsDetails?.map((row) => {
                            let flag = false;
                            Object.keys(row || {}).map((key) => {
                              let record = row[key];
                              if (record && record.length == 0) return false;
                              if ((key == "dns_mx") & (record.length > 0))
                                mxRecord = true;
                            });
                          });

                          if (!mxRecord) {
                            console.log("No MX records found.");
                          }
                        }
                        return (
                          <tr key={index}>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(res?._id)}
                                    checked={selectedIds?.includes(res?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                            <td>
                              <span>{index + 1}</span>
                            </td>
                            <td>
                              {" "}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedId(res);
                                  setModal(true);
                                }}
                              >
                                {res?.original}
                              </span>
                            </td>

                            <td>
                              {" "}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedId(res);
                                  setModal(true);
                                }}
                              >
                                {res?.lookalike}
                              </span>
                            </td>
                            <td>{res?.fuzzer}</td>

                            <td>
                              {" "}
                              {res?.ownerDetails?.Creation_Date
                                ? moment(res?.creationDate?.CreationDate).format(
                                  "DD/MM/YYYY hh:mm"
                                )
                                : "NA"}
                            </td>
                            <td>
                              {" "}
                              {res?.ownerDetails?.Registrar_Registration_Expiration_Date
                                ? moment(res?.expirationDate).format(
                                  "DD/MM/YYYY hh:mm a"
                                )
                                : "NA"}
                            </td>
                            <td>
                              {res?.dnsDetails?.map((row) => {
                                return Object.keys(row || {}).map((key) => {
                                  let record = row[key];
                                  if (Array.isArray(record)) {
                                    // Check if record is an array
                                    if (record.length === 0) return false;
                                    if (key !== "dns_a") return false;
                                    return (
                                      <>
                                        {record.map((val) => {
                                          // Now map over record safely
                                          return (
                                            <>
                                              <span>{val}</span>
                                              <br />
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  } else {
                                    return null; // If record is not an array, return null or handle it accordingly
                                  }
                                });
                              })}
                            </td>
                            <td>
                              {mxRecord ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: "&#10004",
                                  }}
                                ></span>
                              ) : (
                                "X"
                              )}
                            </td>
                            <td>{res?.ownerDetails?.Registrar || "NA"}</td>
                            <td>
                              {res?.requestStatus && res?.requestStatus != "" ? (
                                res?.requestStatus
                              ) : (
                                <button
                                  className="btn btn-danger btn-sm mr-1"
                                  // to={''}
                                  onClick={() => takeDownValue(res)}
                                >
                                  Request
                                  <br />
                                  Takedown
                                </button>
                              )}
                            </td>
                            <td>
                              <p className="cursor"
                                onClick={() => {
                                  setSelectedId(res);
                                  setModal(true);
                                }}
                              >
                                Details
                              </p>
                            </td>
                            <td>
                              {res?.updatedAt
                                ? moment(res?.updatedAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                                : res?.createdAt
                                  ? moment(res?.createdAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                  : "NA"}
                            </td>
                            {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                              <td>
                                <Box sx={{ width: 200 }}>
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedValue}
                                      onChange={(event) => {
                                        setAction(event.target.value);
                                        snooze(res?._id, event.target.value);
                                      }}
                                    >
                                      <MenuItem value="pleaseSelect">
                                        Snooze Options
                                      </MenuItem>
                                      <MenuItem
                                        value="riskAccepted"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId(res._id);
                                        }}
                                      >
                                        Risk Accepted
                                      </MenuItem>
                                      <MenuItem
                                        value="markAsFalsePositive"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId(res._id);
                                        }}
                                      >
                                        False Positive
                                      </MenuItem>
                                      <MenuItem value="markAsRemidiated">
                                        Remediated
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </td>
                            )}
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(res?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal fade Form-Wizard-modal ${isModal ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Details
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModal(false)}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body lookalike">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body">
                        <div id="wizard_horizontal">
                          <Tabs
                            defaultActiveKey="First Step"
                            id="uncontrolled-tab-example"
                          >
                            <Tab
                              eventKey="First Step"
                              title="DNS Details"
                              tabClassName="form_wizard_tab form_wizard_width"
                            >
                              <div className="row clearfix">
                                {/* <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    exportToCSV(downloadDnsDetail, "DNS Details");
                                  }}
                                  style={{ float: "right" }}
                                  href="/add-report"
                                  className="btn btn-primary mr-1"
                                >
                                  <i className="fa fa-file-excel-o"></i>
                                </a> */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                  <table
                                    className="table table-custom spacing0 mb-0"
                                    ref={dnsTableRef}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Server Type</th>
                                        <th>Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedRow?.dnsDetails?.map((row) => {
                                        return Object.keys(row || {}).map(
                                          (key) => {
                                            let record = row[key];
                                            if (record && record.length == 0)
                                              return false;
                                            return (
                                              <>
                                                <tr>
                                                  <td>{dnsKeyName(key)}</td>
                                                  <td>
                                                    {key == "dns_mx"
                                                      ? record?.length > 0
                                                        ? record?.map(
                                                          (val) => val?.exchange
                                                        )
                                                        : "NA"
                                                      : record?.length > 0
                                                        ? record?.map((val) => val)
                                                        : "NA"}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="Second Step"
                              title="Domain Owner Details"
                              tabClassName="form_wizard_tab form_wizard_width"
                            >
                              <div className="row clearfix">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                  <table
                                    className="table table-custom spacing0 mb-0"
                                    ref={ownerTableRef}
                                  >
                                    <tbody>
                                      <tr>
                                        <td>Domain Name</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Domain_Name || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registry Domain ID</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registry_Domain_ID || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrar WHOIS Server</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrar_WHOIS_Server || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrar URL</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrar_URL || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Updated Date</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Updated_Date ? moment(selectedRow?.ownerDetails?.Updated_Date).format("DD-MM-YYYY HH:MM") : "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Creation Date</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Creation_Date ? moment(selectedRow?.ownerDetails?.Creation_Date).format("DD-MM-YYYY HH:MM") : "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Registrar Registration Expiration Date
                                        </td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrar_Registration_Expiration_Date ? moment(selectedRow?.ownerDetails?.Registrar_Registration_Expiration_Date).format("DD-MM-YYYY HH:MM") : "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrar</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrar || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrar IANA ID</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrar_IANA_ID || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrar Abuse Contact Email</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrar_Abuse_Contact_Email || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrar Abuse Contact Phone</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrar_Abuse_Contact_Phone || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Domain Status</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Domain_Status || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registry Registrant ID</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registry_Registrant_ID || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Name</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Name || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Organization</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Organization || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Street</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Street || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant City</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_City || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant State/Province</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_State_Province || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Postal Code</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Postal_Code || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Country</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Country || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Phone</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Phone || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Fax</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Fax || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registrant Email</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registrant_Email || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registry Admin ID</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registry_Admin_ID || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Name</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Name || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Organization</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Organization || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Street</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Street || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin City</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_City || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin State/Province</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_State_Province || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Postal Code</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Postal_Code || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Country</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Country || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Phone</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Phone || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Fax</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Fax || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Admin Email</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Admin_Email || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Registry Tech ID</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Registry_Tech_ID || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Name</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Name || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Organization</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Organization || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Street</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Street || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech City</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_City || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech State/Province</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_State_Province || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Postal Code</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Postal_Code || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Country</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Country || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Phone</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Phone || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Fax</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Fax || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tech Email</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Tech_Email || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Name Server</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Name_Server || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>DNSSEC</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.DNSSEC || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          URL of the ICANN WHOIS Data Problem
                                          Reporting System
                                        </td>
                                        <td>
                                          {selectedRow?.ownerDetails?.ICANN_WHOIS_Data_Problem_Reporting_System_URL || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td> Last update of WHOIS database</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Last_Update_of_WHOIS_Database || "NA"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>TERMS OF USE</td>
                                        <td>
                                          {selectedRow?.ownerDetails?.Terms_Of_Use || "NA"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal fade Form-Wizard-modal ${isModalDns ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Image
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalDns(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body">
                        <img
                          src={`${IMAGE_URL}/images/${selectedImage}`}
                          style={{ width: "700px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal fade Form-Wizard-modal ${isTakeDownModal ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Website Takedown Request
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setTakeDownModal(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card card_ck">
                      <div className="body">
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={stolenLocation}
                              onChange={(e) => setStolenLocation(e.target.value)}
                              placeholder="Stolen Location"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={contenSource}
                              onChange={(e) => setContenSource(e.target.value)}
                              placeholder="Content Source"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={infringingIp}
                              onChange={(e) => setInfringingIp(e.target.value)}
                              placeholder="Infringing IP"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <label>Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={editorContent}
                            onChange={handleEditorChange}
                            config={{
                              styles: [
                                `
              .ck-editor__editable {
                min-height: 200px; /* Set your desired minimum height */
              }
              p, h1, h2, h3 {
                color: black; /* Set text color to black */
              }
            `,
                              ],
                            }}
                          />
                          {/* <TinyMCE
                          content="<p></p>"
                          config={{
                            plugins: "autolink link image lists print preview",
                            toolbar:
                              "undo redo | bold italic | alignleft aligncenter alignright",
                          }}
                          onChange={handleEditorChange}
                        /> */}
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button
                            className="btn btn-primary mr-1 pull-right"
                            // to={''}
                            style={{ marginTop: "10px" }}
                            onClick={() => submitTakeDownValue()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal fade Form-Wizard-modal ${isModalTable === "Form Wizard modal" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModalTable("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className=" ">
                  <div className="card mb-0">
                    <div className="row clearfix">

                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>

                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setModalTable("");
                    setActionNote("");
                  }}
                  className="btn  btn-primary btn-brder-detail"
                  style={{ marginRight: "10px" }}
                >
                  <i class="fa-solid fa-xmark mr-1"></i> Cancel
                </button>
                <button
                  type="button"
                  style={{ fontWeight: "bold" }}
                  onClick={submit}
                  className="btn  btn-primary"
                >
                  <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                </button>



              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Domains;
