import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchMobileApp,
  fetchMobileTotal,
  fetchScanDetail,
  fetchMobileRemidiatedFalseTrue,
} from "../../actions/fakeMobileApp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Columnchart from "../common/barchart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toastr } from "react-redux-toastr";
import {
  takeDown
} from "../../actions/look-alike";

const MobileApp = () => {
  const [filterData, setfilterData] = useState("all");
  const [type, settype] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [value, setvalue] = useState("totals");
  const [totalItems, setTotalItems] = useState(0);
  const [fillActive, setFillActive] = useState("tab1");
  const [activeTab, setActiveTab] = useState("tab1");
  const [category, setCategory] = useState("all");
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = useRef(null);

  const [isTakeDownModal, setTakeDownModal] = useState("");
  const [stolenLocation, setStolenLocation] = useState("");
  const [contenSource, setContentSource] = useState("");
  const [infringingIp, setInfringingIp] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [rowId, setRowId] = useState("");
  const [subject, setSubject] = useState("");



  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  let totalData = useSelector(
    (state) => state?.fakeMobileApp?.mobileData?.data
  );
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    fetchMobileTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchScanDetail(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchMobileApp(id, dispatch, page, type, category);
  }, [id, dispatch, page, type, category]);

  useEffect(() => {
    setTotalItems(totalData?.totalApps);
  }, [totalData?.totalApps]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const auth = useSelector((state) => state?.auth?.user);

  const state = useSelector(
    (state) => state?.fakeMobileApp?.mobileData?.data?.data
  );
  const totalCount = useSelector(
    (state) => state?.fakeMobileApp.totalCount?.data
  );
  const filteredState = state?.filter((item) => !item.isBlacklist);
  const scanCompleted = useSelector(
    (state) => state?.fakeMobileApp?.scanCompleted
  );

  const android = state?.filter(
    (val) => val.source == "Android" || val.appStoreResource == "Android"
  );
  const iOS = state?.filter(
    (val) => (val.source || val.appStoreResource || "")?.toLowerCase() == "ios"
  );
  const thirdParty = state?.filter(
    (val) =>
      val.source == "Third Party Store" ||
      val.appStoreResource == "Third Party Store"
  );

  const scanDetails = useSelector(
    (state) => state?.fakeMobileApp?.totalExcel?.data
  );

  console.log(scanDetails, "scanDetails");

  const android_percent = ((android?.length || 0) / (state?.length || 0)) * 100;
  const ios_percent = ((iOS?.length || 0) / (state?.length || 0)) * 100;
  const third_party_percent =
    ((thirdParty?.length || 0) / (state?.length || 0)) * 100;
  const downloadCsvData = state?.map((res) => ({
    StoreName: res?.source || res?.appStoreResource,
    ApplicationName: res?.app_name,
    ApplicationUrl: res?.url,
  }));

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/get-appsv2/${id}/excel`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Rogue Mobile Application Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        lookalikeAppIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchMobileRemidiatedFalseTrue(id, dispatch, data, page, type, category);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      lookalikeAppIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchMobileRemidiatedFalseTrue(id, dispatch, data, page, type, category);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      lookalikeAppIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchMobileRemidiatedFalseTrue(id, dispatch, data, page, type, category);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  let androidApps = [],
    iosApps = [],
    thirdPartyApps = [];
  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails[details]?.length);
    console.log(
      scanDetails.scanDetails[details],
      "scanDetails.scanDetails[details]"
    );
    let androidApp = scanDetails.scanDetails[details]?.filter(
      (val) =>
        val.source == "google" ||
        val.appStoreResource == "google" ||
        val.source == "Android"
    );
    let iosApp = scanDetails.scanDetails[details]?.filter(
      (val) =>
        (val.source || val.appStoreResource || "")?.toLowerCase() == "ios"
    );
    let thirdPartyApp = scanDetails.scanDetails[details]?.filter(
      (val) =>
        val.source == "thirdparty" ||
        val.appStoreResource == "thirdparty" ||
        val.source == "Third Party Store"
    );

    androidApps.push(androidApp?.length || 0);
    iosApps.push(iosApp?.length || 0);
    thirdPartyApps.push(thirdPartyApp?.length || 0);
  }

  console.log(androidApps, "androidApps");

  let mapData = filteredState;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    // const firstPageIndex = (pageba - 1) * PageSize;
    // const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData;
  }

  const storeName = (val) => {
    if (val == "iOS") return "iOS";
    else if (val == "Android" || val == "google") return "Android";
    else return "Third Party";
  };

  const submitTakeDownValue = () => {
    if (subject == "") {
      toastr.error("Error", "Subject cannot be empty.");
      return false;
    }
    if (stolenLocation == "") {
      toastr.error("Error", "Stolen location cannot be empty.");
      return false;
    }
    if (contenSource == "") {
      toastr.error("Error", "Content source cannot be empty.");
      return false;
    }
    if (infringingIp == "") {
      toastr.error("Error", "Infringin IP cannot be empty.");
      return false;
    }
    if (editorContent == "") {
      toastr.error("Error", "Editor content cannot be empty.");
      return false;
    }

    setTakeDownModal(false);
    takeDown(
      {
        subject,
        stolenLocation,
        contenSource,
        infringingIp,
        editorContent,
        rowId,
        id,
        page: "mobile"
      },
      dispatch
    );
  };

  const handleEditorChange = (even, editor) => {
    const data = editor.getData();
    setEditorContent(data); // Update the state with the new content
  };

  const takeDownValue = (res) => {
    let ip;
    res?.dnsDetails?.map((row) => {
      return Object.keys(row || {}).map((key) => {
        let record = row[key];
        if (record && record.length == 0) return false;
        if (key !== "dns_a") return false;
        record?.map((val) => {
          ip = val;
        });
      });
    });
    let originalWebsite = client.website;
    let domainName = res.url;
    setStolenLocation(originalWebsite);
    setContentSource(domainName);
    setInfringingIp(ip);
    setRowId(res._id);
    setTakeDownModal(true);
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Rogue Mobile Applications</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li className="breadcrumb-item active" aria-current="page">
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalCount?.individualRating}</b>
                </span>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {client?.subscriptionType != "trial" && state?.length !== 0 && (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    style={{
                      float: "right",

                      fontWeight: "bold",
                    }}
                    href="/add-report"
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o mr-1"
                      style={{}}
                    ></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}
        {/* <div className="row clearfix">
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-indigo text-white rounded-circle">
                    <i className="fa fa-briefcase"></i>
                  </div>
                  <div className="ml-4">
                    <span>Total Profiles</span>
                    <h4 className="mb-0 font-weight-medium">12</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-azura text-white rounded-circle">
                    <i className="fa fa-credit-card"></i>
                  </div>
                  <div className="ml-4">
                    <span>Detected as Issues</span>
                    <h4 className="mb-0 font-weight-medium">12</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-orange text-white rounded-circle">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="ml-4">
                    <span>Change in last 30 days</span>
                    <h4 className="mb-0 font-weight-medium">$25,805</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="card">
              <div className="body">
                <div className="d-flex align-items-center">
                  <div className="icon-in-bg bg-pink text-white rounded-circle">
                    <i className="fa fa-life-ring"></i>
                  </div>
                  <div className="ml-4">
                    <span>Change in last 90 days</span>
                    <h4 className="mb-0 font-weight-medium">$13,651</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="card mb-0">
              <div className="body" style={{}}>
                <div className="row text-center">
                  <div className="col-md-4">
                    <div className="card-box">
                      <div className="d-flex justify-content-between">
                        <i className="font-50 fa fa-android icon-dashboard"></i>
                        <h4 className="font-44 total-h4">
                          {totalCount?.totalAndroidApps || 0}
                        </h4>
                      </div>
                      <label className="mb-0 text-right">
                        Android Apps
                      </label>
                    </div>

                  </div>

                  <div className="col-md-4">
                    <div className="card-box">
                      <div className="d-flex justify-content-between">
                        <i className="font-50 fa fa-apple icon-dashboard"></i>
                        <h4 className="font-44 total-h4">
                          {totalCount?.totaliOSApps || 0}
                        </h4>
                      </div>
                      <label className="mb-0 text-right">
                        iOS Apps
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card-box mr-0">
                      <div className="d-flex justify-content-between">
                        <i className="font-50 fa fa-mobile icon-dashboard"></i>
                        <h4 className="font-44 total-h4">
                          {totalCount?.totalThirdPartyApps || 0}
                        </h4>
                      </div>
                      <label className="mb-0 text-right">
                        Third Party
                      </label>
                    </div>

                  </div>


                </div>

              </div>
            </div>
          </div>

          <div className="col-md-4">

            <div className="card-box">
              <div className="form-group list-with-number">
                <label className="d-block">
                  Android Apps{" "}
                  <span className="float-right">
                    {(totalCount?.totalAndroidApps || 0)?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={totalCount?.totalAndroidApps?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: totalCount?.totalAndroidApps?.toFixed(0) + "%",
                    }}
                  ></div>
                </div>
              </div>

              <div className="form-group list-with-number">
                <label className="d-block">
                  iOS Apps{" "}
                  <span className="float-right">
                    {(totalCount?.totaliOSApps || 0)?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={totalCount?.totaliOSApps?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: totalCount?.totaliOSApps?.toFixed(0) + "%",
                    }}
                  ></div>
                </div>
              </div>

              <div className="form-group list-with-number">
                <label className="d-block">
                  Third Party Apps{" "}
                  <span className="float-right">
                    {(totalCount?.totalThirdPartyApps || 0)?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={totalCount?.totalThirdPartyApps?.toFixed(
                      0
                    )}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width:
                        totalCount?.totalThirdPartyApps?.toFixed(0) + "%",
                    }}
                  ></div>
                </div>
              </div>
              {client?.subscriptionType == "trial" && (
                <div className="card border-warning">
                  <TrialComponent />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-8">
            <div className="card  white-box-dashboard user_statistics">
              <div
                className="body"
                id="graphBody"
                style={{ minHeight: "400px" }}
              >
                <h6>Previous scan details</h6>

                {scanDetails && (
                  <Columnchart
                    dates={scanDetails?.dates}
                    androidApps={androidApps}
                    iosApps={iosApps}
                    thirdPartyApps={thirdPartyApps}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                }`}
              onClick={() => {
                setCategory("all");
                settype("all");
                setPage(1);
                setSelectedIds([]);
              }}
            >
              Current
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                }`}
              onClick={() => {
                setCategory("risk-accepted");
                settype("all");
                setPage(1);
                setSelectedIds([]);
              }}
            >
              Risk Accepted
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                }`}
              onClick={() => {
                setCategory("false-positive");
                settype("all");
                setPage(1);
                setSelectedIds([]);
              }}
            >
              False Positive
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                }`}
              onClick={() => {
                setCategory("remidiated");
                settype("all");
                setPage(1);
                setSelectedIds([]);
              }}
            >
              Remediated
            </a>
          </li>
        </ul>
        <div className="card">
          <div className="body">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      onChange={(e) => settype(e.target.value)}
                      value={type}
                    >
                      <option value="all">All</option>
                      <option value="android">Android</option>
                      <option value="ios">iOS</option>
                      <option value="third-party">Third Party</option>
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze("", event.target.value);
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th>
                              <input
                                type="checkbox"
                                checked={
                                  selectedIds == 0 ? false : multiCheckTrue
                                }
                                onClick={() => {
                                  multicheck(currentTableData);
                                }}
                                className="checkUncheck"
                              />
                            </th>
                          )}
                        <th>#</th>
                        <th>Store Name</th>
                        <th style={{ width: "80" }}>Application Name</th>
                        <th style={{ width: "80" }}>Application Url</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <td colSpan={8}>
                            <NoData />
                          </td>
                        </tr>
                      ) : (
                        currentTableData?.map(
                          (res, index) =>
                            !res.isBlacklist && (
                              <tr key={index}>
                                {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                  category === "risk-accepted" ||
                                  category === "false-positive") && (
                                    <td>
                                      <input
                                        onClick={() => singleClick(res?._id)}
                                        checked={selectedIds?.includes(res?._id)}
                                        type="checkbox"
                                        className="checkUncheck"
                                      />
                                    </td>
                                  )}
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  {storeName(
                                    res?.source || res?.appStoreResource
                                  )}
                                </td>
                                <td style={{ whiteSpace: "unset" }}>
                                  {res?.app_name}
                                </td>
                                <td style={{ whiteSpace: "unset" }}>
                                  <a
                                    href={`${res?.url}`}
                                    target="_blank"
                                    style={{}}
                                  >
                                    {res?.url}
                                  </a>
                                </td>
                                <td>
                                  {res?.requestStatus && res?.requestStatus != "" ? (
                                    res?.requestStatus
                                  ) : (
                                    <button
                                      className="btn btn-danger btn-sm mr-1"
                                      // to={''}
                                      onClick={() => takeDownValue(res)}
                                    >
                                      Request
                                      <br />
                                      Takedown
                                    </button>
                                  )}
                                </td>
                                {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                  <td>
                                    <Box sx={{ width: 200 }}>
                                      <FormControl fullWidth>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectedValue}
                                          onChange={(event) => {
                                            setAction(event.target.value);
                                            snooze(
                                              res?._id,
                                              event.target.value
                                            );
                                          }}
                                        >
                                          <MenuItem value="pleaseSelect">
                                            Snooze Options
                                          </MenuItem>
                                          <MenuItem
                                            value="riskAccepted"
                                            onClick={() => {
                                              addModal();
                                              setLookalikeAppId([res._id]);
                                            }}
                                          >
                                            Risk Accepted
                                          </MenuItem>
                                          <MenuItem
                                            value="markAsFalsePositive"
                                            onClick={() => {
                                              addModal();
                                              setLookalikeAppId([res._id]);
                                            }}
                                          >
                                            False Positive
                                          </MenuItem>
                                          <MenuItem value="markAsRemidiated">
                                            Remediated
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </td>
                                )}
                                {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                                  category === "false-positive") && (
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm mr-1 Unsnooze"
                                        onClick={() => Unsnoozed(res?._id)}
                                      >
                                        Unsnooze
                                      </button>
                                    </td>
                                  )}
                              </tr>
                            )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className=" ">
                  <div className="card mb-0">
                    <div className="row clearfix">

                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>

                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="btn  btn-primary btn-brder-detail"
                  style={{ marginRight: "10px" }}
                >
                  <i class="fa-solid fa-xmark mr-1"></i> Cancel
                </button>
                <button
                  type="button"
                  style={{ fontWeight: "bold" }}
                  onClick={submit}
                  className="btn  btn-primary"
                >
                  <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                </button>



              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${isTakeDownModal ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Mobile Application Takedown Request
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setTakeDownModal(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card card_ck">
                      <div className="body">
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={stolenLocation}
                              onChange={(e) => setStolenLocation(e.target.value)}
                              placeholder="Stolen Location"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              value={contenSource}
                              onChange={(e) => setContentSource(e.target.value)}
                              placeholder="Content Source"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <label>Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={editorContent}
                            onChange={handleEditorChange}
                            config={{
                              styles: [
                                `
              .ck-editor__editable {
                min-height: 200px; /* Set your desired minimum height */
              }
              p, h1, h2, h3 {
                color: black; /* Set text color to black */
              }
            `,
                              ],
                            }}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button
                            className="btn btn-danger btn-sm mr-1 pull-right"
                            // to={''}
                            style={{ marginTop: "10px" }}
                            onClick={() => submitTakeDownValue()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileApp;
