import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
    fetchTakeDown,
    fetchTakeDownAction
} from "../../actions/customer";
import { Link, useParams } from "react-router-dom";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { update } from "immutable";
import { toast } from "react-toastify";
import { toastr } from "react-redux-toastr";


const TakeDown = () => {
    const dispatch = useDispatch();

    var date = new Date();
    date = moment(date).add(1, 'year').toDate();
    const [isModal, setModal] = useState(false);
    const [isModalTwo, setisModalTwo] = useState('');
    const [updateId, setUpdateId] = useState('');
    const [reminderModal, setReminderModal] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState("");
    const [approvalId, setApprovalId] = useState("");
    const [emailId, setEmailId] = useState("");
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(15);
    const [search, setSearch] = useState("");
    const [filterData, setfilterData] = useState("all");
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [reason, setReason] = useState("");



    // console.log(auth, "auth");
    let customers = useSelector((state) => state?.customer?.takeDown?.data);

    useEffect(() => {
        fetchTakeDown(dispatch);
    }, []);


    let mapData =
        filterData === "all"
            ? customers
            : filterData === "client"
                ? null
                : filterData === "mssp"
                    ? null
                    : null;

    mapData =
        search == ""
            ? mapData
            : mapData?.filter(
                (row) =>
                    row?.orgName?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1 ||
                    row?.orgContactName
                        ?.toLowerCase()
                        ?.indexOf(search?.toLowerCase()) != -1 ||
                    row?.orgEmail?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1
            );

    let currentTableData = [];

    if (mapData && mapData.length > 0) {
        // setFirstLoad(false)
        const firstPageIndex = (page - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
    }
    const downloadCsvData = customers?.map((res) => ({
        Client: res?.orgName,
        PrimaryContactName: res?.orgContactName,
        Email: res?.orgEmail,
        Contact: res?.orgContact,
        Website: res?.website,
    }));

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, fileName + ".xlsx");
    };


    const setModalUpdate = (id) => {
        setUpdateId(id)
        setisModalTwo('Form Wizard modal two')
    }

    const submit = () => {
        if (status == "") {
            toastr.error("Error", 'Please select the status');
            return
        }
        let data = {
            status: status,
            comment: comment,
            reason: reason
        }
        fetchTakeDownAction(dispatch, updateId, data).then((res) => {
            setisModalTwo('')
        }).catch()
    }


    //console.log(threatAnalyist, domains.length, "domains && domains.length");
    return (
        <div className="container-fluid">
            <div className="block-header">
                <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                        <h1 style={{ color: 'white' }}>Dashboard</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Take Down Request
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="body">
                            <div className="row clearfix">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Take down request"
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                                setPage(1);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-12"></div>
                                <div className="col-lg-3 col-md-4 col-sm-12"></div>
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    {customers?.length === 0 ? null : (
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                exportToCSV(downloadCsvData, "Client List");
                                            }}
                                            style={{ float: "right" }}
                                            href="/add-report"
                                            className="btn btn-primary mr-1"
                                        >
                                            <i className="fa fa-file-excel-o"></i>
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-custom spacing5 mb-0">
                                    <tbody>
                                        <tr>
                                            <th>Subject</th>
                                            <th>Stolen Location</th>
                                            <th>Conten Source</th>
                                            <th>Infringing Ip</th>
                                            <th>Editor Content</th>
                                            <th>Status</th>
                                            <th>createdAt</th>
                                            <th>Action</th>
                                        </tr>
                                        {currentTableData?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <p className="mb-0">{item.subject}</p>
                                                    </td>
                                                    <td>
                                                        <td>
                                                            {item.stolenLocation}
                                                        </td>
                                                    </td>
                                                    <td>
                                                        <span>{item.contenSource}</span>
                                                    </td>

                                                    <td>
                                                        {item?.infringingIp}
                                                    </td>
                                                    <td>{item.editorContent || ""}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <span>
                                                            {" "}
                                                            {moment(item.createdAt).format(
                                                                "DD/MM/YYYY hh:mm a"
                                                            )}{" "}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => setModalUpdate(item?._id)}
                                                            className="btn btn-primary btn-sm mr-1 Unsnooze"
                                                        >
                                                            Update
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="dataTables_paginate">
                                {mapData && mapData.length > 0 && (
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={page}
                                        totalCount={mapData?.length}
                                        pageSize={PageSize}
                                        onPageChange={(page) => setPage(page)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`modal fade Form-Wizard-modal ${isModal ? "d-block show" : ""
                    }`}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {approvalStatus == "Approve" ? "Approve?" : "Reject?"}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                {approvalStatus == "Approve" ? "Are you sure to approve the request." : "Are you sure ro reject the request."}
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-round btn-default"
                                data-dismiss="modal"
                                onClick={() => setModal(false)}
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`modal fade Form-Wizard-modal ${reminderModal ? "d-block show" : ""
                    }`}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Reminder
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Do you really want to send reminder to this user?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-round btn-default"
                                data-dismiss="modal"
                                onClick={() => setReminderModal(false)}
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade Form-Wizard-modal ${isModalTwo === "Form Wizard modal two" ? "d-block show" : ""
                    }`}
            >
                <div className="modal-dialog modal-lg" id="clientModal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Update Take Down Request
                            </h5>
                            <button
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setisModalTwo("");
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body pricing_page modal_mob">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="card">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <label className="col-lg-6 col-md-12">Status</label>
                                            <div className="form-group ol-lg-12 col-md-12">
                                                <select
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value="">Please select the status</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="reject">Reject</option>
                                                    <option value="complete">Complete</option>
                                                </select>
                                            </div>
                                        </div>
                                        {status == 'reject' ? <>
                                            <div className="col-md-12">
                                                <label className="col-lg-12 col-md-12">Reason</label>
                                                <div className="form-group col-lg-12 col-md-12">
                                                    <textarea
                                                        onChange={(e) => setReason(e.target.value)}
                                                        value={reason}
                                                        className="form-control"
                                                        rows="3"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </> : <>
                                            <div className="col-md-12">
                                                <label className="col-lg-12 col-md-12">Comment</label>
                                                <div className="form-group col-lg-12 col-md-12">
                                                    <textarea
                                                        onChange={(e) => setComment(e.target.value)}
                                                        value={comment}
                                                        className="form-control"
                                                        rows="3"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                    <div className="row">
                                        <div
                                            className="col-lg-12 col-md-12"
                                            style={{ marginRight: "28px" }}
                                        >
                                            <button
                                                type="button"
                                                style={{ color: "black", fontWeight: "bold" }}
                                                onClick={submit}
                                                className="btn btn-round btn-primary mr-1 pull-right"
                                            >
                                                Submit
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setisModalTwo("");
                                                }}
                                                className="btn btn-round btn-default pull-right"
                                                style={{ marginRight: "10px" }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TakeDown;
