import axios from "axios";
import { API_URL } from "../utils/constant";
import {
  CUSTOMERS_FETCHED,
  CUSTOMER_CREATED,
  CREATING_CUSTOMER,
  EDIT_CUSTOMER_FETCHED,
  UPDATING_CUSTOMER,
  CUSTOMER_UPDATED,
  DELETING_CUSTOMER,
  CUSTOMER_DELETED,
  FETCHING_CUSTOMERS,
  FETCHING_THREAT_ANALYIST,
  CREATING_VENDOR,
  VENDORS_FETCHED,
  VENDOR_CREATED,
  FETCHING_VENDORS,
  UPDATE_COUNTER,
  GETING_DATA_BY_LOCALSTORAGE,
  USER_LOGGED_IN,
  GET_TRIAL_REQUEST,
  CREATING_FOREST_VIEW,
  GET_FOREST_VIEW,
  FOREST_GRAPH_ATTACKSURFACE,
  FOREST_GRAPH_DATAEXPOSURE,
  FOREST_GRAPH_BRANDSECURITY,
  FETCHING_TAKEDOWN
} from "./index";
import { toastr } from "react-redux-toastr";


export const fetchCustomers = (props) => (dispatch) => {

  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_CUSTOMERS,
    payload: null,
  });
  axios
    .get(`${API_URL}/customer`, {})
    .then((response) => {
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};


export const fetchTakeDown = (dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_TAKEDOWN,
    payload: null,
  });
  axios
    .get(`${API_URL}/getTakeDownRequest`, {})
    .then((response) => {
      dispatch({
        type: FETCHING_TAKEDOWN,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FETCHING_TAKEDOWN,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchTakeDownAction = (dispatch, id, data) => {
  return new Promise((resolve, reject) => {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: FETCHING_TAKEDOWN,
      payload: null,
    });
    axios
      .post(`${API_URL}/actionOnTakeDown/${id}`, data)
      .then((response) => {
        if (response.data.error) {
          toastr.error("Error", response.data.userMessage);
        }
        else {
          toastr.success("Success", response.data.userMessage);
          resolve(response.data);
        }
        dispatch({
          type: GET_FOREST_VIEW,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        reject(message);
      });
  });
};

export const createCustomer = (props, auth, dispatch) => {
  return new Promise((resolve, reject) => {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    dispatch({
      type: CREATING_CUSTOMER,
      payload: null,
    });
    axios
      .post(`${API_URL}/customer`, { ...props })
      .then((response) => {
        if (response.data.error) {
          toastr.error("Error", response.data.userMessage);
          dispatch({
            type: CUSTOMER_CREATED,
            payload: { error: true, userMessage: response.data.userMessage },
          });
          reject(response.data.userMessage);
        } else {
          toastr.success("Success", response.data.userMessage);
          dispatch({
            type: CUSTOMERS_FETCHED,
            payload: response.data,
          });
          if (auth?.organisationId?.clientType == 3) {
            dispatch({
              type: USER_LOGGED_IN,
              payload: response.data.user,
            });
          }
          resolve();
        }
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        toastr.error("Error", message);
        // dispatch({
        //   type: CUSTOMER_CREATED,
        //   payload: { error: true, userMessage: message },
        // });
        reject(message); // Reject promise if error occurs
      });
  });
};

// export const createCustomer = (props, auth, dispatch) => {
//   // const cookie = new Cookies();
//   // const session = cookie.get("session");
//   const session = localStorage.getItem("session");
//   axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
//   dispatch({
//     type: CREATING_CUSTOMER,
//     payload: null,
//   });
//   axios
//     .post(`${API_URL}/customer`, { ...props })
//     .then((response) => {
//       // window.location.href = '/customers'
//       if (response.data.error)
//         toastr.error("Error", response.data.userMessage);
//       else
//         toastr.success("Success", response.data.userMessage);
//       dispatch({
//         type: CUSTOMERS_FETCHED,
//         payload: response.data,
//       });
//       if (auth?.organisationId?.clientType == 3) {
//         // console.log(response.data.user,response.data.data,'response.data.data.user')
//         dispatch({
//           type: USER_LOGGED_IN,
//           payload: response.data.user,
//         });
//       }
//     })
//     // .catch((err) => {
//     //   var message = "Some server error has occurred.";
//     //   if (err.response) {
//     //     message = err.response.data.userMessage;
//     //   }
//     //   dispatch({
//     //     type: CUSTOMER_CREATED,
//     //     payload: { error: true, userMessage: message },
//     //   });
//     // });
// };

export const createVendor = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: CREATING_VENDOR,
    payload: null,
  });
  axios
    .post(`${API_URL}/vendor`, { ...props })
    .then((response) => {
      // window.location.href = '/customers'
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: VENDORS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: VENDOR_CREATED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const updateVendor = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: VENDORS_FETCHED,
  //   payload: null,
  // });
  axios
    .put(`${API_URL}/vendor`, { ...props, customerId: props.orgId })
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: VENDORS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: VENDORS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const customerApproveToggle = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .put(`${API_URL}/customer`, { ...props })
    .then((response) => {
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchCustomersById = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  dispatch({
    type: FETCHING_CUSTOMERS,
    payload: null,
  });

  axios
    .get(`${API_URL}/customer/${props}`, {})
    .then((response) => {
      dispatch({
        type: EDIT_CUSTOMER_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: EDIT_CUSTOMER_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const deleteCustomer = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: DELETING_CUSTOMER,
    payload: null,
  });
  axios
    .delete(`${API_URL}/customer/${props}`, {})
    .then((response) => {
      dispatch({
        type: CUSTOMER_DELETED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMER_DELETED,
        payload: { error: true, userMessage: message },
      });
    });
};
export const updateCustomer = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: UPDATING_CUSTOMER,
    payload: null,
  });
  return axios
    .put(`${API_URL}/customer`, { ...props, customerId: props.orgId })
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
        dispatch({
          type: CUSTOMER_UPDATED,
          payload: { error: true, userMessage: response.data.userMessage },
        });
        return Promise.reject(response.data.userMessage);
      } else {
        toastr.success("Success", response.data.userMessage);
        dispatch({
          type: CUSTOMERS_FETCHED,
          payload: response.data,
        });
        return Promise.resolve();
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: CUSTOMER_UPDATED,
        payload: { error: true, userMessage: message },
      });
      return Promise.reject(message);
    });
};
// export const updateCustomer = (props, dispatch) => {
//   // const cookie = new Cookies();
//   // const session = cookie.get("session");
//   const session = localStorage.getItem("session");
//   axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
//   dispatch({
//     type: UPDATING_CUSTOMER,
//     payload: null,
//   });
//   axios
//     .put(`${API_URL}/customer`, { ...props, customerId: props.orgId })
//     .then((response) => {
//       if (response.data.error)
//         toastr.error("Error", response.data.userMessage);
//       else
//         toastr.success("Success", response.data.userMessage);
//       dispatch({
//         type: CUSTOMERS_FETCHED,
//         payload: response.data,
//       });
//     })
//     .catch((err) => {
//       var message = "Some server error has occurred.";
//       if (err.response) {
//         message = err.response.data.userMessage;
//       }

//       dispatch({
//         type: CUSTOMER_UPDATED,
//         payload: { error: true, userMessage: message },
//       });
//     });
// };

export const refreshCustomer = (props) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: CREATING_CUSTOMER,
  //   payload: null,
  // });
  axios
    .post(`${API_URL}/customer/refresh/${props}`)
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        toastr.success("Success", response.data.userMessage);
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      // dispatch({
      //   type: CUSTOMER_CREATED,
      //   payload: { error: true, userMessage: message },
      // });
    });

}

export const fetchThreatAnalyst = (dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_THREAT_ANALYIST,
    payload: null,
  });
  axios
    .get(`${API_URL}/threatAnalyist`, {})
    .then((response) => {
      dispatch({
        type: FETCHING_THREAT_ANALYIST,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FETCHING_THREAT_ANALYIST,
        payload: { error: true, userMessage: message },
      });
    });
}

export const fetchCustomersNew = (dispatch) => {
  //console.log("customercalledn","customer called");

  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_CUSTOMERS,
    payload: null,
  });
  axios
    .get(`${API_URL}/customer`, {})
    .then((response) => {
      console.log(response?.data,'response?.data')
      // const data = response?.data?.data?.filter((data) => data.clientType != 3)
      const data = response?.data?.data?.map((res) => ({
          ...res,
          currentRating: res?.rating?.filter((row) => row?.scanCounter == res?.scanCounter)?.pop(),
        }));
      let data_ = {
        data
      }
      console.log(data_,'resresres')
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: data_,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchVendors = (dispatch) => {
  //console.log("vendorcalled","customer called");

  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_VENDORS,
    payload: null,
  });
  axios
    .get(`${API_URL}/vendor`, {})
    .then((response) => {
      dispatch({
        type: VENDORS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: VENDORS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const deleteCustomerNew = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: DELETING_CUSTOMER,
  //   payload: null,
  // });
  axios
    .delete(`${API_URL}/customer/${props}`, {})
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const updateScanCounter = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: VENDORS_FETCHED,
  //   payload: null,
  // });
  axios
    .put(`${API_URL}/updatedScanCounter/${props.scanCounter}/${props.orgId}`)
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      localStorage.setItem("org", JSON.stringify(response.data.data));
      dispatch({
        type: GETING_DATA_BY_LOCALSTORAGE,
        localClient: response.data.data,
      });
      window.location.reload();
      // dispatch({
      //   type: VENDORS_FETCHED,
      //   payload: response.data,
      // });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: VENDORS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const logout = () => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("refreshToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: VENDORS_FETCHED,
  //   payload: null,
  // });
  axios
    .delete(`${API_URL}/logout?param=logout`)
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else{
        window.location.replace("/login");
        toastr.success("Success", response.data.userMessage);
        localStorage.clear();
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
    });
};


export const getTrialRequest = (dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_VENDORS,
    payload: null,
  });
  axios
    .get(`${API_URL}/trialRequest`, {})
    .then((response) => {
      dispatch({
        type: GET_TRIAL_REQUEST,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: GET_TRIAL_REQUEST,
        payload: { error: true, userMessage: message },
      });
    });
}

export const updateTrialRequest = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: VENDORS_FETCHED,
  //   payload: null,
  // });
  axios
    .post(`${API_URL}/trialRequest/approve/${props.id}?status=${props.status}`)
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else {
        toastr.success("Success", response.data.userMessage);
        dispatch({
          type: GET_TRIAL_REQUEST,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: VENDORS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const sendUserInvite = (props) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
 
  axios
    .get(`${API_URL}/send-reminder/${props.email}`)
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      
    });
}

export const createCustomerforestView = (props, id, auth, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: GET_FOREST_VIEW,
    payload: null,
  });
  axios.post(`${API_URL}/organisation/${id}/fv-client/`, { ...props })
    .then((response) => {
      // window.location.href = '/customers'
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: GET_FOREST_VIEW,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: GET_FOREST_VIEW,
        payload: { error: true, userMessage: message },
      });
    });
};

export const uploadexcelforest = (props, id, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: GET_FOREST_VIEW,
    payload: null,
  });
  axios.post(`${API_URL}/organisation/${id}/fv-client/excel`, props )
    .then((response) => {
      // window.location.href = '/customers'
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: GET_FOREST_VIEW,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: GET_FOREST_VIEW,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchCustomersforestView = (orgId, dispatch, page, orgName, sortField, sortOrder) => {
  //console.log("customercalledn","customer called");

  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: GET_FOREST_VIEW,
    payload: null,
  });
  axios
    .get(`${API_URL}/organisation/${orgId}/fv-client/?page=${page}&limit=10&orgName=${orgName}&sortField=${sortField}&sortOrder=${sortOrder}`)
    .then((response) => {
      dispatch({
        type: GET_FOREST_VIEW,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: GET_FOREST_VIEW,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchCustomersforestViewSearch = (orgId, dispatch, tech, search) => {
  //console.log("customercalledn","customer called");

  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: GET_FOREST_VIEW,
    payload: null,
  });
  axios
    .get(`${API_URL}/forestView/organizations/${orgId}/search/${tech}/${search}`)
    .then((response) => {
      dispatch({
        type: GET_FOREST_VIEW,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: GET_FOREST_VIEW,
        payload: { error: true, userMessage: message },
      });
    });
};

export const forestGraphAttack = (orgId ,dispatch) => {
  // Forest View
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FOREST_GRAPH_ATTACKSURFACE,
    payload: null,
  });
  axios
    .get(`${API_URL}/forestView/organizations/${orgId}/stats/attacksurface/graph`)
    .then((response) => {
      dispatch({
        type: FOREST_GRAPH_ATTACKSURFACE,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FOREST_GRAPH_ATTACKSURFACE,
        payload: { error: true, userMessage: message },
      });
    });
};

export const forestGraphDataexposure = (orgId ,dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FOREST_GRAPH_DATAEXPOSURE,
    payload: null,
  });
  axios
    .get(`${API_URL}/forestView/organizations/${orgId}/stats/dataexposure/graph`)
    .then((response) => {
      dispatch({
        type: FOREST_GRAPH_DATAEXPOSURE,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FOREST_GRAPH_DATAEXPOSURE,
        payload: { error: true, userMessage: message },
      });
    });
};

export const forestGraphBrandSecurity = (orgId ,dispatch) => {
  // Forest View
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FOREST_GRAPH_BRANDSECURITY,
    payload: null,
  });
  axios
    .get(`${API_URL}/forestView/organizations/${orgId}/stats/brandsecurity/graph`)
    .then((response) => {
      dispatch({
        type: FOREST_GRAPH_BRANDSECURITY,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FOREST_GRAPH_BRANDSECURITY,
        payload: { error: true, userMessage: message },
      });
    });
};

export const deleteCustomerforest = (props, id, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .delete(`${API_URL}/organisation/${props}/fv-client/${id}`, {})
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: GET_FOREST_VIEW,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: GET_FOREST_VIEW,
        payload: { error: true, userMessage: message },
      });
    });
};

export const updateCustomerforest = (props,id, updateId, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .put(`${API_URL}/organisation/${id}/fv-client/${updateId}`, { ...props})
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: GET_FOREST_VIEW,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: GET_FOREST_VIEW,
        payload: { error: true, userMessage: message },
      });
    });
};