import axios from "axios";
import { API_URL } from "../utils/constant";
import { WATCHTOWER_HIT } from "./index";
import { toastr } from "react-redux-toastr";

export const updateRunningContainers = () => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .post(`${API_URL}/update_container`, { })
    .then((response) => {
      dispatch({
        type: WATCHTOWER_HIT,
        payload: response.data,
      });
      toastr.success('Success', 'Container updated successfully');
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage || message;
        toastr.error('Error', message);
      } else if (err.request) {
        message = "No response from server. Please try again later.";
        toastr.error('Error', message);
      } else {
        message = `Error: ${err.message}`;
        toastr.error('Error', message);
      }
      dispatch({
        type: WATCHTOWER_HIT,
        payload: { error: true, userMessage: message },
      });
    });
};