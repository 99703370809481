import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AccessPage from "../Shared/accessPage";
import { createAlert, getAlert } from "../../actions/integration";


const AlertIntegration = () => {
    const dispatch = useDispatch();

    const id = useSelector(state => {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    });

    const results = useSelector((state) => state?.integration?.alertList?.data);
    
    const [alertConfig, setAlertConfig] = useState({
        attackSurface: {
            ipDiscoveryOpenPorts: false,
            passiveVulnerability: false,
            activeVulnerability: false,
            subdomain: false,
            outdatedWebTechnologies: false,
            spfDmarc: false,
            vulnerableSslCertificates: false,
            blacklistedIp: false
        },
        dataExposure: {
            leakedCredentials: false,
            malwareCredentials: false,
            leakedSessions: false,
            leakedSourceCode: false,
            pastebinSearch: false,
            breachedPersonalInformation: false,
            telegramMonitoring: false,
            postmanMonitoring: false,
            openCloudBuckets: false,
            darkWebMonitoring: false,
            vipMonitoring: false
        },
        brandSecurity: {
            rogueMobile: false,
            lookaLike: false,
            socialMediaProfiles: false,
            brandMentions: false
        },
        extras: {
            consolidateMail: false,
            noNewFindings: false
        }
    });

    useEffect(() => {
        if (id) {
            getAlert(id, dispatch)
        }
    }, [id]);

    useEffect(()=>{
        setAlertConfig(results || {
            attackSurface: {
                ipDiscoveryOpenPorts: false,
                passiveVulnerability: false,
                activeVulnerability: false,
                subdomain: false,
                outdatedWebTechnologies: false,
                spfDmarc: false,
                vulnerableSslCertificates: false,
                blacklistedIp: false
            },
            dataExposure: {
                leakedCredentials: false,
                malwareCredentials: false,
                leakedSessions: false,
                leakedSourceCode: false,
                pastebinSearch: false,
                breachedPersonalInformation: false,
                telegramMonitoring: false,
                postmanMonitoring: false,
                openCloudBuckets: false,
                darkWebMonitoring: false,
                vipMonitoring: false
            },
            brandSecurity: {
                rogueMobile: false,
                lookaLike: false,
                socialMediaProfiles: false,
                brandMentions: false
            },
            extras: {
                consolidateMail: false,
                noNewFindings: false
            }
        })
    },[results])


    const handleSwitchChange = (section, field) => (e) => {
        if (!e || e.target === null) return;
        const isChecked = e.target.checked || false;
        setAlertConfig(prev => {
            const currentSection = prev[section] || {};
            
            return {
                ...prev,
                [section]: {
                    ...currentSection,
                    [field]: isChecked
                }
            };
        });
    }

    const handleSectionToggle = (section) => (e) => {
        const isChecked = e.target.checked;
        setAlertConfig(prev => ({
            ...prev,
            [section]: Object.keys(prev[section]).reduce((acc, key) => ({
                ...acc,
                [key]: isChecked
            }), {})
        }));
    };

    const onSubmit = ()=>{
        createAlert(id,{scanCompletionAlertConfig:alertConfig},id)
    }

    return (
        <>
            <div className="container-fluid">
                <AccessPage />
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Third Party Risk Management</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page">
                                        integration 
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        details 
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/alert-integrations">Manage Alerts</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between pb-3 mb-4 align-items-center" style={{ borderBottom: '1px solid #666565' }}>
                                            <div className="checkBox">
                                                Attack Surface
                                                <label className="switch mr-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={Object.values(alertConfig?.attackSurface)?.every(v => v)}
                                                        onChange={handleSectionToggle('attackSurface')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="checkBox">
                                                Data Exposure
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={Object.values(alertConfig?.dataExposure)?.every(v => v)}
                                                        onChange={handleSectionToggle('dataExposure')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                            <div className="checkBox">
                                                Brand Security
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={Object.values(alertConfig?.brandSecurity)?.every(v => v)}
                                                        onChange={handleSectionToggle('brandSecurity')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="alert-ul">
                                            <li>
                                                IP Discovery & Open Port
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.ipDiscoveryOpenPorts}
                                                        onChange={handleSwitchChange('attackSurface', 'ipDiscoveryOpenPorts')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Active Vulnerability Assessment
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.activeVulnerability}
                                                        onChange={handleSwitchChange('attackSurface', 'activeVulnerability')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Passive Vulnerability Assessment
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.passiveVulnerability}
                                                        onChange={handleSwitchChange('attackSurface', 'passiveVulnerability')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Subdomain Enumeration
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.subdomain}
                                                        onChange={handleSwitchChange('attackSurface', 'subdomain')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Outdated web technologies
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.outdatedWebTechnologies}
                                                        onChange={handleSwitchChange('attackSurface', 'outdatedWebTechnologies')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                SPF & DMARC Records
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.spfDmarc}
                                                        onChange={handleSwitchChange('attackSurface', 'spfDmarc')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Vulnerable SSL Certificates
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.vulnerableSslCertificates}
                                                        onChange={handleSwitchChange('attackSurface', 'vulnerableSslCertificates')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Blacklisted IP
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.attackSurface?.blacklistedIp}
                                                        onChange={handleSwitchChange('attackSurface', 'blacklistedIp')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="alert-ul">
                                            <li>
                                                Stolen Credentials
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.leakedCredentials}
                                                        onChange={handleSwitchChange('dataExposure', 'leakedCredentials')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Malware Infected Machines
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.malwareCredentials}
                                                        onChange={handleSwitchChange('dataExposure', 'malwareCredentials')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Leaked Sessions
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.leakedSessions}
                                                        onChange={handleSwitchChange('dataExposure', 'leakedSessions')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Source Code Leakage
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.leakedSourceCode}
                                                        onChange={handleSwitchChange('dataExposure', 'leakedSourceCode')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Pastebin Search
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.pastebinSearch}
                                                        onChange={handleSwitchChange('dataExposure', 'pastebinSearch')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Personal Information
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.breachedPersonalInformation}
                                                        onChange={handleSwitchChange('dataExposure', 'breachedPersonalInformation')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Telegram monitoring
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.telegramMonitoring}
                                                        onChange={handleSwitchChange('dataExposure', 'telegramMonitoring')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Postman Monitoring
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.postmanMonitoring}
                                                        onChange={handleSwitchChange('dataExposure', 'postmanMonitoring')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Open Cloud Buckets
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.openCloudBuckets}
                                                        onChange={handleSwitchChange('dataExposure', 'openCloudBuckets')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Dark web monitoring
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.darkWebMonitoring}
                                                        onChange={handleSwitchChange('dataExposure', 'darkWebMonitoring')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                VIP Monitoring
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.dataExposure?.vipMonitoring}
                                                        onChange={handleSwitchChange('dataExposure', 'vipMonitoring')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="alert-ul">
                                            <li>
                                                Look-alike Domains
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.brandSecurity?.lookaLike}
                                                        onChange={handleSwitchChange('brandSecurity', 'lookaLike')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Rogue Mobile Apps
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.brandSecurity?.rogueMobile}
                                                        onChange={handleSwitchChange('brandSecurity', 'rogueMobile')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Social Media Profiles
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.brandSecurity?.socialMediaProfiles}
                                                        onChange={handleSwitchChange('brandSecurity', 'socialMediaProfiles')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                            <li>
                                                Brand Mentions
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={alertConfig?.brandSecurity?.brandMentions}
                                                        onChange={handleSwitchChange('brandSecurity', 'brandMentions')}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <button
                                            className="btn btn-primary btn-sm ml-1"
                                            style={{ color: "black", fontWeight: "bold" }}
                                            onClick={()=>{
                                                onSubmit()
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AlertIntegration;