import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchIpDiscovery,
  createHOst,
  deleteIp,
  uploadIp,
  fetchIpDiscoveryTotal,
  fetchIpDiscoveryGraph,
  fetchIpDiscoveryAction,
  allPort,
  allCountry,
  allCloud,
} from "../../actions/inventory";
import { Link, useParams } from "react-router-dom";
import Tooltip from "../common/toolTip";
import { VectorMap } from "react-jvectormap";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import $, { data } from "jquery";
import { toastr } from "react-redux-toastr";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import Loader from "../Shared/loader";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    minWidth: "600px",
    minHeight: "300px",
  },
}));

const AssetDiscovery = () => {
  var ipCriticalityEnable = false;
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [isModal, setModal] = useState(false);
  const [isModaltwo, setModalTwo] = useState(false);
  const [isModalhost, setModalHost] = useState("");
  const [hosts, setHosts] = useState([]);
  const [isModalTrue, setModalSet] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalBody, setModalBody] = useState([]);
  const [applyCountrySearch, setApplyCountrySearch] = useState([]);
  const [applyCloudSearch, setApplyCloudSearch] = useState([]);
  const [applyPortSearch, setApplyPortSearch] = useState([]);
  const [certModal, setCertModal] = useState(false);
  const [certModalBody, setCertModalBody] = useState();
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [PageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [addIpModel, setAddIpModel] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [addHost, setHost] = useState("");
  const classes = useStyles();
  const [ipRemoveModal, setIpRemoveModal] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  const [ipId, setIpId] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  const latest = queryParameters.get("latest");
  const auth = useSelector((state) => state?.auth?.user);
  const [loading, setLoading] = useState(false);
  const [domainFilter, setDomainFilter] = useState("all");
  const [expandedRows, setExpandedRows] = useState(new Set());


  const showSpinner = () => {
    setLoading(true);
  };

  const hideSpinner = () => {
    setLoading(false);
  };
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });


  if (id == "62088cb69f0b0c58a632706a") {
    ipCriticalityEnable = true;
    console.log(ipCriticalityEnable, "ipcriticalityenable")
  }


  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  let domain = client?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  if (domain?.charAt(domain.length - 1) == "/") {
    domain = [domain.substr(0, domain.length - 1)];
    // domain.unshift("all");
  } else {
    domain = [domain];
    // domain.unshift("all");
  }
  if (client?.domains && client.domains.length > 0) {
    domain = client.domains;
    // domain.unshift("all");
  }

  let table = useSelector((state) => state?.inventory?.ipdiscovery?.data?.data);
  let tableTotal = useSelector(
    (state) => state?.inventory?.ipdiscovery?.data?.totalRecords
  );
  let countRes = useSelector((state) => state?.inventory?.assetstotal?.data);
  let lengthOfPort = useSelector(
    (state) => state?.inventory?.allport?.data?.ports
  );
  let lengthOfLocation = useSelector(
    (state) => state?.inventory?.allcountry?.data?.countries
  );
  let lengthOfcloudLocations = useSelector(
    (state) => state?.inventory?.allcloud?.data?.cloudProviders
  );

  const scanCompleted = useSelector((state) => state.inventory.scanCompleted);
  const distinctLocationData = useSelector(
    (state) => state?.inventory?.assetsgraph?.data?.scanDetails
  );

  let counts;

  const markers = [
    // Your array of map markers
    // { latLng: [40.7128, -74.006], name: 'New York', style: { fill: '#ff0000' } }, // Red marker for New York
    //   { latLng: [34.0522, -118.2437], name: 'Los Angeles', style: { fill: '#00ff00' } }, // Green marker for Los Angeles
    //   { latLng: [41.8781, -87.6298], name: 'Chicago', style: { fill: '#0000ff' } }, // Blue marker for Chicago
    // // Add more markers as needed
  ];

  const hostOpen = (res) => {
    setHosts(res);
    setModalHost("Form Wizard modal host");
  };

  const values = {
    // Your object with region values
    // us: 500, // Example value for the United States
    // Add more region values as needed
  };

  // for (let marker = 0; marker < distinctLocationData?.length; marker++) {
  //   if (distinctLocationData[marker] && distinctLocationData[marker].country) {
  //     let obj = {};
  //     obj.latLng = [
  //       distinctLocationData[marker].latitide,
  //       distinctLocationData[marker].longitude,
  //     ];
  //     if (
  //       distinctLocationData[marker].country == "" ||
  //       distinctLocationData[marker].country == undefined
  //     ) {
  //       obj.name = "" + "";
  //     } else {
  //       obj.name =
  //         distinctLocationData[marker].country +
  //         " - " +
  //         counts['"' + distinctLocationData[marker].country + '"'];
  //       markers.push(obj);
  //       console.log(markers, "markers");
  //       values[
  //         (
  //           countryToCode[distinctLocationData[marker].country] || ""
  //         ).toUpperCase()
  //       ] = "#ffec94";
  //     }
  //   }
  // }

  const onModalOpen = () => {
    setModalSet(true);
  };

  const addModal = () => {
    setModalTwo("Form Wizard modal two");
  };

  const multiIp = (ip) => {
    const allIds = ip?.map((item) => item.ip);

    const data = {
      action: "markAsIncorrectIp",
      ips: allIds,
      actionNote: "",
    };
    fetchIpDiscoveryAction(
      id,
      dispatch,
      page,
      category,
      applyPortSearch,
      applyCountrySearch,
      applyCloudSearch,
      search,
      data,
      domainFilter
    );
    setSelectedValue("pleaseSelect");
    setActionNote("");
  };

  let mapData = table;
  let currentTableData = [];

  if (mapData) {
    currentTableData = mapData;
  }

  useEffect(() => {
    if (orgId) {
      fetchIpDiscovery(
        orgId,
        dispatch,
        page,
        category,
        applyPortSearch,
        applyCountrySearch,
        applyCloudSearch,
        search,
        domainFilter
      );
    } else {
      fetchIpDiscovery(
        id,
        dispatch,
        page,
        category,
        applyPortSearch,
        applyCountrySearch,
        applyCloudSearch,
        search,
        domainFilter
      );
    }
  }, [
    id,
    dispatch,
    page,
    category,
    applyPortSearch,
    applyCountrySearch,
    applyCloudSearch,
    search,
    domainFilter,
    orgId,
  ]);

  useEffect(() => {
    setTotalItems(tableTotal);
  }, [tableTotal]);

  useEffect(() => {
    if (table == null) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [table]);

  useEffect(() => {
    allPort(id, dispatch, category);
  }, [id, dispatch, category]);

  useEffect(() => {
    allCountry(id, dispatch, category);
  }, [id, dispatch, category]);

  useEffect(() => {
    allCloud(id, dispatch, category);
  }, [id, dispatch, category]);

  useEffect(() => {
    fetchIpDiscoveryTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchIpDiscoveryGraph(id, dispatch);
  }, [id, dispatch]);

  const riskOpen = (res) => {
    // console.log(res,'resssssss');
    setHosts(res);
    setModalHost("Port");
  };

  const tableCheck = table?.map(
    (res) => res?._id === table.find((item) => item?.ip === res?._id)?.ip
  );
  const result = tableCheck?.every((check) => check);

  let port = [];
  if (result) {
    port =
      table?.flatMap((res) =>
        res?.port?.flatMap((restwo) => restwo?.map((item) => item?.port))
      ) || [];
  } else {
    port = table?.map((res) => res?.services?.map((res) => res?.port));
  }

  const networkService = table?.data?.map((res) =>
    res?.services?.map((res) => res?.service_name)
  );

  const services = table?.data?.map((res) =>
    res.services.map((res) => res?.service_name)
  );

  const createHost = () => {
    let host = $("#hostName").val();
    if (!host) {
      toastr.error("Error", "Please enter host address.");
      return false;
    }
    createHOst({ organisationId: id, ip: host }, dispatch);
  };

  const modalBodyData = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setAddIpModel(false)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div>
        <h2>Add Targets</h2>
        <div>
          <div className="row">
            <label className="col-form-label col-md-4">Host Name</label>
            <div className="col-md-7">
              <input
                type="text"
                className="form-control"
                // style={{ width: 200 }}
                id="hostName"
                placeholder="Add Host"
                value={addHost}
                onChange={(e) => setHost(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-right mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => createHost()}
                >
                  Add Host
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  let ports = [];
  for (let i = 0; i < port?.length; i++) {
    for (let j = 0; j < port[i]?.length; j++) {
      ports.push(port[i][j]);
    }
  }

  let servicesNetwork = [];
  for (let i = 0; i < networkService?.length; i++) {
    for (let j = 0; j < networkService[i]?.length; j++) {
      servicesNetwork.push(networkService[i][j]);
    }
  }

  let service = [];
  for (let i = 0; i < services?.length; i++) {
    for (let j = 0; j < services[i]?.length; j++) {
      service.push(services[i][j]);
    }
  }

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event, ip, ips) => {
    let ids = [];
    ids.push(_id);
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      const data = {
        action: "markAsRemidiated",
        ipIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchIpDiscoveryAction(
        id,
        dispatch,
        page,
        category,
        applyPortSearch,
        applyCountrySearch,
        applyCloudSearch,
        search,
        data,
        domainFilter
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }

    if (event == "markAsIncorrectIp") {
      const filteredData = ips.filter((obj) => selectedIds.includes(obj._id));
      const allIds = filteredData?.map((item) => item.ip);
      const data = {
        action: "markAsIncorrectIp",
        ips: allIds == 0 || undefined ? ip : allIds,
        actionNote: "",
      };
      fetchIpDiscoveryAction(
        id,
        dispatch,
        page,
        category,
        applyPortSearch,
        applyCountrySearch,
        applyCloudSearch,
        search,
        data,
        domainFilter
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setMultiCheckTrue(false);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      ipIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchIpDiscoveryAction(
      id,
      dispatch,
      page,
      category,
      applyPortSearch,
      applyCountrySearch,
      applyCloudSearch,
      search,
      data,
      domainFilter
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      ipIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchIpDiscoveryAction(
      id,
      dispatch,
      page,
      category,
      applyPortSearch,
      applyCountrySearch,
      applyCloudSearch,
      search,
      data,
      domainFilter
    );
    setModalTwo("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const handleChange = (e) => {
    const fileReader = new FileReader();
    let fileType = e.target.files[0].type;
    if (fileType != "application/json")
      toastr.error("Error", "Please upload json file.");

    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (val) => {
      try {
        let result = JSON.parse(val.target.result);
        uploadIp({ organisationId: id, ip: result }, dispatch);
      } catch (e) {
        toastr.error("Error", "Invalid json file.");
      }
    };
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/digital-inventory/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "IP Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid">
      {loading && <Loader />}
      <AccessPage></AccessPage>
      <Modal
        open={addIpModel}
        onClose={() => setAddIpModel(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyData}
      </Modal>
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>IP Discovery</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : {countRes?.individualRating || "N/A"}</b>
              </span>
            </nav>
          </div>
          {auth.accessLevel === "Admin" && (
            <div className="col-md-6 col-sm-12">
              <div
                className="custom-file"
                style={{ width: "300px", float: "right" }}
              >
                {/* <span>Upload IP</span> */}
                <input
                  onChange={handleChange}
                  type="file"
                  className="custom-file-input"
                  id="inputGroupFile01"
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  Upload IP
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {typeof scanCompleted !== "undefined" && !scanCompleted && (
        <div className="card border-success">
          <ScanMessage />
        </div>
      )}

      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="row">
            <div className="col-md-4">
              <div className="card-box">
                <div className="d-flex justify-content-between">
                  <i className="fa fa-map-marker icon-dashboard"></i>

                  <h4 className="font-44 total-h4">
                    {countRes?.totalHostsOrIPs || 0}
                  </h4>
                </div>

                <label className="mb-0 text-right">Host/IP Discovered</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box">
                <div className="d-flex justify-content-between">
                  <i className="fa fa-globe icon-dashboard"></i>

                  <h4 className="font-44 total-h4">
                    {countRes?.totalCountries || 0}
                  </h4>
                </div>

                <label className="mb-0 text-right">Country</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box">
                <div className="d-flex justify-content-between">
                  <i className="fa fa-cloud icon-dashboard"></i>

                  <h4 className="font-44 total-h4">
                    {countRes?.totalCloudOrISPProviders || 0}
                  </h4>
                </div>

                <label className="mb-0 text-right">Cloud / ISP Provider</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box">
                <div className="d-flex justify-content-between">
                  <i className="fa fa-usb icon-dashboard"></i>

                  <h4 className="font-44 total-h4">
                    {countRes?.totalOpenPorts || 0}
                  </h4>
                </div>

                <label className="mb-0 text-right">Open Ports</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box">
                <div className="d-flex justify-content-between">
                  <i className="fa fa-windows icon-dashboard"></i>

                  <h4 className="font-44 total-h4">
                    {countRes?.totalOperatingSystems || 0}
                  </h4>
                </div>

                <label className="mb-0 text-right">Operating Systems</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-box">
                <div className="d-flex justify-content-between">
                  <i className="fa fa-wifi icon-dashboard"></i>

                  <h4 className="font-44 total-h4">
                    {countRes?.totalNetworkServices || 0}
                  </h4>
                </div>

                <label className="mb-0 text-right">Network Services</label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="white-box-dashboard border-box">
            {/* <h4>Asset Graph</h4> */}
            <div id="world-map-markers" className="jvector-map main-map">
              <VectorMap
                containerStyle={{
                  height: "300px",
                }}
                map={"world_mill"}
                backgroundColor="transparent"
                borderColor="#fff"
                borderOpacity={0.25}
                borderWidth={0}
                color="#e6e6e6"
                regionStyle={{ initial: { fill: "#6c757d" } }}
                markerStyle={{
                  initial: {
                    r: 5,
                    fill: "#fff",
                    "fill-opacity": 1,
                    stroke: "#000",
                    "stroke-width": 1,
                    "stroke-opacity": 0.4,
                  },
                }}
                markers={distinctLocationData}
                series={{
                  regions: [
                    {
                      values: values,
                      attribute: "fill",
                    },
                  ],
                }}
                hoverOpacity={null}
                normalizeFunction="linear"
                zoomOnScroll={false}
                scaleColors={["#000000", "#000000"]}
                selectedColor="#000000"
                selectedRegions={[]}
                enableZoom={false}
                hoverColor="#fff"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-table-tab nav-link ${
                  category === "all" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("all");
                  setPage(1);
                  setSearch("");
                  setApplyCountrySearch([]);
                  setApplyCloudSearch([]);
                  setApplyPortSearch([]);
                  setSelectedIds([]);
                }}
              >
                Current
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "risk-accepted" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("risk-accepted");
                  setPage(1);
                  setSearch("");
                  setApplyCountrySearch([]);
                  setApplyCloudSearch([]);
                  setApplyPortSearch([]);
                  setSelectedIds([]);
                }}
              >
                Risk Accepted
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "false-positive" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("false-positive");
                  setPage(1);
                  setSearch("");
                  setApplyCountrySearch([]);
                  setApplyCloudSearch([]);
                  setApplyPortSearch([]);
                  setSelectedIds([]);
                }}
              >
                False Positive
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "remidiated" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("remidiated");
                  setPage(1);
                  setSearch("");
                  setApplyCountrySearch([]);
                  setApplyCloudSearch([]);
                  setApplyPortSearch([]);
                  setSelectedIds([]);
                }}
              >
                Remediated
              </a>
            </li>
          </ul>
          <div className="card hide-close-box">
            <div className="body">
              <div className="header">
                <div className="row clearfix">
                  <div className="col-sm-3">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={domainFilter}
                      onChange={(e) => setDomainFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      {domain?.map((row, index) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                    {/* domain */}
                  </div>
                  <div className="col-sm-2">
                    {/* <h2>Discovered IP Details</h2> */}
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      onChange={(event, newValue) => {
                        setApplyPortSearch(newValue);
                      }}
                      value={applyPortSearch}
                      options={lengthOfPort || []}
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Port"
                          color="white"
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      onChange={(event, newValue) => {
                        setApplyCountrySearch(newValue);
                      }}
                      value={applyCountrySearch}
                      options={lengthOfLocation || []}
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Country"
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      onChange={(event, newValue) => {
                        setApplyCloudSearch(newValue);
                      }}
                      value={applyCloudSearch}
                      options={lengthOfcloudLocations || []}
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Cloud Provider"
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-2">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-1">
                    {mapData?.length === 0 ? null : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFile();
                        }}
                        style={{ float: "right" }}
                        href="/add-report"
                        className="btn btn-primary mr-1"
                      >
                        <i className="fa fa-file-excel-o" style={{}}></i>
                      </a>
                    )}
                  </div>
                </div>
                <div className="row clearfix justify-content-end">
                  <div className="col-sm-4 d-flex justify-content-end m-2">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze(
                                "",
                                event.target.value,
                                "",
                                currentTableData
                              );
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                            <MenuItem
                              value="markAsIncorrectIp"
                              onClick={() => {}}
                            >
                              Incorrect Ip
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-custom spacing0 mb-0">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" &&(category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                        <th>#</th>
                        <th>IP Address</th>
                        <th>Location</th>
                        <th>Cloud / ISP Provider</th>
                        <th>Open Ports</th>
                        {ipCriticalityEnable && (
                          <th>Criticality</th>
                        )}
                        <th>Network Services</th>
                        <th>Operating System</th>
                        <th>Hosts</th>
                        <th>Last Updated on</th>
                        {/* <th>Actions</th> */}
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                            <th style={{ width: "80" }}>Action</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr onClick={onModalOpen}>
                              {auth?.accessLevel != "Threat Analyst" &&(category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(row?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                              <td>
                                <span>{index + 1}</span>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Tooltip text="Avatar Name" id="client1" />
                                  <div
                                    className="ml-3"
                                    style={{
                                     
                                      fontWeight: 400,
                                      fontSize: 14,
                                    }}
                                  >
                                    {row?.ip || "NA"}
                                  </div>
                                </div>
                              </td>
                              <td>{row?.location || "NA"}</td>
                              <td>{row?.cloudOrISPProvider || "NA"}</td>
                              <td>
                                <div className="relative">
                                  <div className="d-flex flex-wrap">
                                    {(expandedRows.has(row.id) ? row?.openPorts : row?.openPorts?.slice(0, 5))?.map((port, index) => (
                                      <span key={index} className="badge d-inline-block m-1">
                                        {port}
                                      </span>
                                    ))}
                                    {row?.openPorts?.length > 5 && (
                                      <div className="relative group">
                                        <span
                                          className="badge d-inline-block m-1 cursor-pointer"
                                          onClick={() => {
                                            setExpandedRows(prev => {
                                              const newSet = new Set(prev);
                                              if (newSet.has(row.id)) {
                                                newSet.delete(row.id);
                                              } else {
                                                newSet.add(row.id);
                                              }
                                              return newSet;
                                            });
                                          }}
                                        >
                                          {expandedRows.has(row.id) ? 'Show less' : `+${row.openPorts.length - 5} more`}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              {/* <td>
                                {row?.openPorts?.map((port) => {
                                  return <span className="badge">{port}</span>;
                                })}
                              </td> */}
                              {ipCriticalityEnable && (
                                <td>{row?.highestSeverity || "NA"}
                                  <p style={{ cursor: "pointer" }} onClick={() => riskOpen(row)}>(Detail)</p></td>
                              )}
                              <td>
                                {row?.networkServices?.map((service) => {
                                  return (
                                    <span className="badge badge-warning">
                                      {service}
                                    </span>
                                  );
                                })}
                              </td>
                              <td>{row?.operatingSystem || "NA"}</td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => hostOpen(row)}
                              >
                                Host Detail
                              </td>
                              <td>
                                {row?.updatedAt
                                  ? moment(row?.updatedAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                  : row?.createdAt
                                  ? moment(row?.createdAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                  : "NA"}
                              </td>
                              {/* <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-default"
                                  title="Delete"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  onClick={() => {
                                    setIpId(row?._id);
                                    setIpRemoveModal(true);
                                  }}
                                >
                                  <i className="icon-trash"></i>
                                </button>
                              </td> */}
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(
                                            row?._id,
                                            event.target.value,
                                            row?.ip,
                                            currentTableData
                                          );
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                        <MenuItem value="markAsIncorrectIp">
                                          Incorrect Ip
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                              {auth?.accessLevel != "Threat Analyst" &&(category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(row?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModalhost == "Port" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Risk Details
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalHost("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Impact</th>
                          <th>Recommendations</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hosts?.impacts?.map((impact, index) => (
                          <tr key={index}>
                            <td style={{ whiteSpace: 'pre-wrap' }}>{impact}</td>
                            <td style={{ whiteSpace: 'pre-wrap' }}>{hosts?.recommendations?.[index]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModaltwo === "Form Wizard modal two" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalTwo("");
                  setActionNote("");
                }}
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="">
                <div className="card mb-0">
                  <div className="row clearfix">
                     
                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
                     
                    
                     <button
                       type="button"
                       onClick={() => {
                         setModalTwo("");
                         setActionNote("");
                       }}
                       className="btn  btn-primary btn-brder-detail"
                       style={{ marginRight: "10px" }}
                     >
                      <i class="fa-solid fa-xmark mr-1"></i>  Cancel
                     </button>
                     <button
                       type="button"
                       style={{ fontWeight: "bold" }}
                       onClick={submit}
                       className="btn  btn-primary"
                     >
                      <i class="fa-solid fa-floppy-disk mr-1"></i>  Submit
                     </button>
                    
                 </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModalhost === "Form Wizard modal host" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Hosts
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalHost("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                      <div className="col-lg-12 col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Host</th>
                              <th>Last Updated on</th>
                            </tr>
                          </thead>
                          <tbody>
                            {hosts?.hosts?.map((host) => (
                              <tr key={host}>
                                <td>{host}</td>
                                <td>
                                  {moment(hosts?.updatedAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg assetsDiscoveryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalHeading}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <section>
                  <div className="row">
                    <div className="col-sm-12">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          downloadFile();
                        }}
                        href="/add-report"
                        style={{ float: "right" }}
                        className="btn btn-primary mr-1"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card">
                    {/* <div className="body"> */}
                    <div className="table-responsive">
                      {/* <table className="table table-hover table-custom spacing5">
                        <thead>
                          <tr>
                            {Object.keys(modalBody).map((val) => {
                              return (
                                <th style={{ width: "200" }}>
                                  {val?.replace(/['"]+/g, "")}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.keys(modalBody).map((val) => {
                              let value = modalBody[val];
                              return (
                                <td style={{ verticalAlign: "top" }}>
                                  {value?.map((row) => {
                                    return (
                                      <>
                                        <span>{row}</span>
                                        <br />
                                      </>
                                    );
                                  })}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table> */}
                    </div>
                    {/* </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          certModal ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg assetsDiscoveryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Certificate
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setCertModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <section>
                  <div
                    className="card"
                    dangerouslySetInnerHTML={{
                      __html: certModalBody?.replace(/\n/g, "<br />"),
                    }}
                  >
                    {/* {certModalBody}
                    dangerouslySetInnerHTML={{__html: alert.alertContent}} */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className={`modal fade  ${ipRemoveModal ? "d-block show" : ""}`}
        id="exampleModal"
        onClick={() => setIpRemoveModal(false)}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setIpRemoveModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  deleteIp(ipId, id, false, dispatch);
                  setIpRemoveModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div
          className={`modal fade Form-Wizard-modal ${
            isModalTrue == true ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New User
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalSet("")}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                  <div className="row clearfix">
                      <div className="col-lg-6 col-md-12">
                        <label className="col-lg-12 col-md-12">Primary Contact Name</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Primary Contact Name *"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <label className="col-lg-6 col-md-12">Email address</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <input
                            type="email"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};
export default AssetDiscovery;
