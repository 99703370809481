import {
  LOOK_ALIKE_FETCHED,
  DOMAIN_SQUATING,
  DOMAIN_SQUATING_TOTAL,
  DOMAIN_SQUATING_DOMAIN,
  DOMAIN_SQUATING_GRAPH,
  LOOK_ALIKE_FETCHED_BY_ID,
  DOMAIN_SQUATING_UPDATE
} from "../actions/";

export default function (
  state = {
    loading: true,
    response: { lookalike: [] },
    list: null,
    lookalikeById: [],
    scanCompleted: true,
    lookaliketotal: [],
    lookalikeGraph: [],
    subDomainList: []
  },
  action
) {
  switch (action.type) {
    case LOOK_ALIKE_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
      };
    case DOMAIN_SQUATING_DOMAIN:
      return {
        ...state,
        subDomainList: action.payload,
      };
    case DOMAIN_SQUATING_UPDATE:
      console.log(state?.domainSquating?.data?.data, 'state?.domainSquating', action.payload?.data?._id.toString());
      let val = state?.domainSquating?.data?.data.map((item) =>
        item._id.toString() == action.payload?.data?._id.toString() ? { ...item, requestStatus: "Pending" } : item
      )
    case DOMAIN_SQUATING_TOTAL:
      return {
        ...state,
        lookaliketotal: action.payload,
      };
    case DOMAIN_SQUATING_GRAPH:
      return {
        ...state,
        lookalikeGraph: action.payload,
      };
    case DOMAIN_SQUATING:
      return {
        ...state,
        domainSquating: action.payload,
        // list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        scanCompleted: action.payload.scanCompleted,
      };
    case LOOK_ALIKE_FETCHED_BY_ID:
      return {
        ...state,
        lookalikeById: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
