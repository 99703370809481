import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  brandMention,
  createHOst,
  deleteIp,
  uploadIp,
} from "../../actions/inventory";
import { Link, useParams } from "react-router-dom";
import Tooltip from "../common/toolTip";
import { VectorMap } from "react-jvectormap";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import { countryToCode } from "../../utils/countryCode";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  takeDown
} from "../../actions/look-alike";
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    minWidth: "600px",
    minHeight: "300px",
  },
}));

const AssetDiscovery = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [isModal, setModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalBody, setModalBody] = useState([]);

  const [applyCountrySearch, setApplyCountrySearch] = useState([]);
  const [applyCloudSearch, setApplyCloudSearch] = useState([]);

  const [certModal, setCertModal] = useState(false);
  const [certModalBody, setCertModalBody] = useState();

  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [PageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [addIpModel, setAddIpModel] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [addHost, setHost] = useState("");
  const classes = useStyles();
  const [ipRemoveModal, setIpRemoveModal] = useState(false);
  const [ipId, setIpId] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const latest = queryParameters.get("latest");
  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  const [isTakeDownModal, setTakeDownModal] = useState("");
  const [stolenLocation, setStolenLocation] = useState("");
  const [contenSource, setContenSource] = useState("");
  const [infringingIp, setInfringingIp] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [rowId, setRowId] = useState("");
  const [subject, setSubject] = useState("");

  //console.log(latest,'props.location.search')

  // const id = useSelector(
  //   (state) =>
  //     state?.summary?.localClient?._id ||
  //     state?.summary?.clients?.[0]?._id
  // );
  // const ipData = [
  //   20, 21, 22, 39, 139, 137, 445, 53, 443, 80, 8080, 8443, 23, 25, 69,
  // ];
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const auth = useSelector((state) => state?.auth?.user);

  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  let domains = "";
  client?.domains?.forEach((row) => {
    domains += "," + row;
  });
  // latest
  let table = useSelector((state) => state?.inventory?.brand?.ip_details);
  let rating = useSelector(
    (state) => state?.inventory?.brand?.individualRating
  );
  // console.log(table,'table')

  // console.log(table,'tablebody')

  // table = table?.filter((row) =>  domains?.indexOf(row?.host) == -1);

  const scanCompleted = useSelector((state) => state.inventory.scanCompleted);
  //console.log(scanCompleted, "scanCompleted");
  const scanDetails = useSelector(
    (state) => state?.inventory?.brand?.scanDetailArray
  );

  //console.log("AssetDiscovery", table);

  let openPortArray = [],
    ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    // for(let ineerData = 0; ineerData < scanDetails?.scanDetails[details]?.length; ineerData++){
    let newObj = {},
      openPorts = 0;
    for (
      let services = 0;
      services < scanDetails?.scanDetails[details].services?.length;
      services++
    ) {
      // if (
      //   ipData.includes(
      //     parseInt(scanDetails?.scanDetails[details].services[services].port)
      //   )
      // ) {
      if (scanDetails?.scanDetails[details].services[services].port) {
        openPorts++;
        break;
      }
    }
    openPortArray.push(openPorts);
    ipArray.push(scanDetails?.scanDetails[details]?.length);
  }
  //console.log(ipArray,'scanDetails')

  useEffect(() => {
    brandMention(id, true, dispatch);
  }, [id]);

  // mapping the data for length

  const location = table?.map((value) => value?.location);

  // const distinctLocationData = [...new Set(location?.map(item => item.country))]; // [ 'A', 'B']
  const distinctLocationData = [
    ...new Map(
      location?.map((item) => [item && item["country"], item])
    ).values(),
  ];

  const operatingSystem = table?.filter(
    (row) => row?.operating_system?.product
  );

  // res.services.map((res) => res.service_name)
  let vulnerablePortsList = [];
  for (let count = 0; count < table?.length; count++) {
    for (let inner = 0; inner < table[count]?.services.length; inner++) {
      // if (ipData.includes(table[count]?.services[inner]?.port)) {
      if (table[count]?.services[inner]?.port) {
        vulnerablePortsList.push(table[count]);
        break;
      }
    }
  }
  let uniqueNetworkService = table?.reduce((acc, curr) => {
    curr?.services?.map((res) => {
      const str = JSON.stringify(res?.service_name);
      if (acc[str]) {
        if (acc[str].indexOf(curr?.ip) == -1) acc[str].push(curr?.ip);
      } else {
        acc[str] = [curr?.ip];
      }
      // acc[str] = acc[str]
      return acc;
    });
    return acc;
  }, {});

  let counts = location?.reduce((acc, curr) => {
    if (curr) {
      const str = JSON.stringify(curr["country"]);
      acc[str] = (acc[str] || 0) + 1;
    }
    return acc;
  }, {});

  let markers = [],
    values = {};
  for (let marker = 0; marker < distinctLocationData?.length; marker++) {
    if (distinctLocationData[marker] && distinctLocationData[marker].country) {
      let obj = {};
      obj.latLng = [
        distinctLocationData[marker].coordinates.latitude,
        distinctLocationData[marker].coordinates.longitude,
      ];
      obj.name =
        distinctLocationData[marker].country +
        " - " +
        counts['"' + distinctLocationData[marker].country + '"'];

      markers.push(obj);
      values[countryToCode[distinctLocationData[marker].country]] = "#ffec94";
    }
  }

  // const ip = table?.map((res) => res?.ip);

  // const noDuplicateIp = ip?.filter(
  //   (item, index, self) => self.indexOf(item) === index
  // );

  const port = table?.map((res) => res.services.map((res) => res.port));
  const networkService = table?.map((res) =>
    res.services.map((res) => res.service_name)
  );
  // const location = table?.map((res) => res.location.city);
  const services = table?.map((res) =>
    res.services.map((res) => res.service_name)
  );

  const createHost = () => {
    let host = $("#hostName").val();
    if (!host) {
      toastr.error("Error", "Please enter host address.");
      return false;
    }
    createHOst({ organisationId: id, ip: host }, dispatch);
  };

  const modalBodyData = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setAddIpModel(false)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div>
        <h2>Add Targets</h2>
        <div>
          <div className="row">
            <label className="col-form-label col-md-4">Host Name</label>
            <div className="col-md-7">
              <input
                type="text"
                className="form-control"
                // style={{ width: 200 }}
                id="hostName"
                placeholder="Add Host"
                value={addHost}
                onChange={(e) => setHost(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-right mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => createHost()}
                >
                  Add Host
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // const openPortData = table?.filter(
  //   (res) => res.services && res.services.length > 0 && res.services[0]?.port
  // );

  const distinctLocation = table?.map((res) => res.location?.country);
  // for loop for getting the length of the nested array

  const lengthOfLocation = distinctLocation?.filter(
    (item, index, self) =>
      item && item.trim() !== "" && self?.indexOf(item) === index
  );
  // const locationData = table?.filter((res) => res.location?.city);

  const CloudProvider = table?.map(
    (res) => res?.autonomous_system?.description
  );
  // for loop for getting the length of the nested array
  let lengthOfcloudLocations = [];

  lengthOfcloudLocations = (CloudProvider || []).filter(
    (item, index, self) =>
      item && item.trim() !== "" && self.indexOf(item) === index
  );
  // const cloudLocationData = table?.filter(
  //   (res) => res?.autonomous_system?.description
  // );
  // for loop for getting the length of the nested array
  let ports = [];
  for (let i = 0; i < port?.length; i++) {
    for (let j = 0; j < port[i]?.length; j++) {
      ports.push(port[i][j]);
    }
  }

  let servicesNetwork = [];
  for (let i = 0; i < networkService?.length; i++) {
    for (let j = 0; j < networkService[i]?.length; j++) {
      servicesNetwork.push(networkService[i][j]);
    }
  }

  // getting the data as a nested array
  let service = [];
  for (let i = 0; i < services?.length; i++) {
    for (let j = 0; j < services[i]?.length; j++) {
      service.push(services[i][j]);
    }
  }
  let portsArray = [];
  for (let i = 0; i < port?.length; i++) {
    for (let j = 0; j < port[i]?.length; j++) {
      portsArray.push(port[i][j]);
    }
  }

  //console.log(networkService, "lengthOfNetworkService", lengthOfNetworkService);

  let latestRecord = table?.filter((val) =>
    moment(val.updatedAt).isAfter(client?.scanDetails[client?.scanCounter - 1])
  );

  let mapData =
    filterData === "all"
      ? table
      : filterData === "vulnerable"
      ? vulnerablePortsList
      : filterData === "operatingSystem"
      ? operatingSystem
      : null;

  console.log(mapData, filterData, "mapData 1");
  //console.log(latestRecord,"latestRecord",client?.scanDetails[client?.scanCounter - 1])
  if (latest) {
    mapData = latestRecord;
  }

  let searchedData = [];
  if (search !== "") {
    for (let count = 0; count < mapData?.length; count++) {
      let row = mapData[count],
        flag = true;
      if (row?.ip?.indexOf(search) != -1) {
        searchedData.push(mapData[count]);
        flag = false;
      } else if (row?.host?.indexOf(search) != -1) {
        searchedData.push(mapData[count]);
        flag = false;
      } else if (row?.autonomous_system?.asn == search) {
        searchedData.push(mapData[count]);
        flag = false;
      } else if (row?.location?.country?.toLowerCase()?.indexOf(search) != -1) {
        searchedData.push(mapData[count]);
        flag = false;
      } else if (
        false &&
        row?.location?.city?.toLowerCase().indexOf(search) != -1
      ) {
        searchedData.push(mapData[count]);
        flag = false;
      } else if (row?.autonomous_system?.description?.indexOf(search) != -1) {
        searchedData.push(mapData[count]);
        flag = false;
      }
      if (flag) {
        for (let inner = 0; inner < row?.services?.length; inner++) {
          if (
            row?.services[inner]?.port == search ||
            row?.services[inner]?.service_name?.indexOf(search) != -1
          ) {
            searchedData.push(table[count]);
            break;
          }
        }
      }
    }
  }

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const exportToCSVNew = (fileName) => {
    const downloadExcel = Object.keys(modalBody).map((val) => {
      let value = modalBody[val];
      if (fileName == "Hosts Location") {
        return {
          HostsLocation: val?.replace(/['"]+/g, ""),
          IP: JSON.stringify(value.map((res) => res))
            ?.replace(/[\[\]']+/g, "")
            ?.replace(/['"]+/g, ""),
        };
      } else if (fileName == "Cloud / ISP Provider") {
        return {
          CloudProvider: val?.replace(/['"]+/g, ""),
          IP: JSON.stringify(value.map((res) => res))
            ?.replace(/[\[\]']+/g, "")
            ?.replace(/['"]+/g, ""),
        };
      } else if (fileName == "Network Services") {
        return {
          NetworkServices: val?.replace(/['"]+/g, ""),
          IP: JSON.stringify(value.map((res) => res))
            ?.replace(/[\[\]']+/g, "")
            ?.replace(/['"]+/g, ""),
        };
      }
    });

    const ws = XLSX.utils.json_to_sheet(downloadExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const downloadCsvData = table?.map((res, index) => {
    let services = [];
    res?.services?.map((res1, index) => {
      if (services.indexOf(res1?.service_name) == -1)
        services.push(res1?.service_name);
    });

    return {
      Serial: index + 1,
      IPAddress: res?.ip,
      URL: res?.host,
      Location: res?.location?.country + " " + res?.location?.city,
      CloudProvider: res?.autonomous_system?.description,
      OpenPorts: JSON.stringify(res?.services.map((res) => res.port))?.replace(
        /[\[\]']+/g,
        ""
      ),
      NetworkServices: JSON.stringify(services.map((res) => res))?.replace(
        /[\[\]']+/g,
        ""
      ),
      OperatingSystem: res?.operating_system?.product?.toUpperCase() || "NA",
      LastUpdatedOn: res?.updatedAt
        ? moment(res?.updatedAt).format("DD-MM-YYYY HH:MM")
        : res?.createdAt
        ? moment(res?.createdAt).format("DD-MM-YYYY HH:MM")
        : "NA",
    };
  });

  const handleChange = (e) => {
    const fileReader = new FileReader();
    let fileType = e.target.files[0].type;
    if (fileType != "application/json")
      toastr.error("Error", "Please upload json file.");

    // console.log(e.target.files[0], 'e.target.result')
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (val) => {
      try {
        let result = JSON.parse(val.target.result);
        // console.log("e.target.result", result);
        uploadIp({ organisationId: id, ip: result }, dispatch);
      } catch (e) {
        toastr.error("Error", "Invalid json file.");
      }

      // setFiles(e.target.result);
    };
  };

  mapData = search === "" ? mapData : searchedData;

  // console.log(mapData, 'mapData 2')

  // let countrySearch = [];
  // applyCountrySearch?.reduce((arr, curr) => {
  //   mapData?.map((res) => {
  //     if (res?.location?.country == curr)
  //       countrySearch?.push(res);
  //   })
  // }, {})
  // console.log(countrySearch, "countrySearch")
  // mapData = applyCountrySearch?.length > 0 ? countrySearch : mapData;

  // console.log(mapData, 'mapData 3')

  // let cloudProviderSearch = [];
  // applyCloudSearch?.reduce((arr, curr) => {
  //   mapData?.map((res) => {
  //     if (res?.autonomous_system?.description == curr)
  //       cloudProviderSearch?.push(res);
  //     else if (res?.autonomous_system?.description == "GOOGLE-CLOUD-PLATFORM" && curr == "GOOGLE") {
  //       cloudProviderSearch.push(res);
  //     }
  //   })
  // }, {})
  mapData = mapData;

  // console.log(lengthOfcloudLocations ,'applyCloudSearch')

  let currentTableData = [];

  if (mapData && mapData.length > 0 && firstLoad) {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }

  const submitTakeDownValue = () => {
    if (subject == "") {
      toastr.error("Error", "Subject cannot be empty.");
      return false;
    }
    if (stolenLocation == "") {
      toastr.error("Error", "Stolen location cannot be empty.");
      return false;
    }
    if (contenSource == "") {
      toastr.error("Error", "Content source cannot be empty.");
      return false;
    }
    if (infringingIp == "") {
      toastr.error("Error", "Infringin IP cannot be empty.");
      return false;
    }
    if (editorContent == "") {
      toastr.error("Error", "Editor content cannot be empty.");
      return false;
    }

    setTakeDownModal(false);
    takeDown(
      {
        subject,
        stolenLocation,
        contenSource,
        infringingIp,
        editorContent,
        rowId,
        id,
        page: "mobile"
      },
      dispatch
    );
  };

  const takeDownValue = (res) => {
    let ip;
    res?.dnsDetails?.map((row) => {
      return Object.keys(row || {}).map((key) => {
        let record = row[key];
        if (record && record.length == 0) return false;
        if (key !== "dns_a") return false;
        record?.map((val) => {
          ip = val;
        });
      });
    });
    let originalWebsite = client.website;
    let domainName = res.host;
    ip = res.ip;
    setStolenLocation(originalWebsite);
    setContenSource(domainName);
    setInfringingIp(ip);
    setRowId(res._id);
    setTakeDownModal(true);
  };

  const handleEditorChange = (even, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      <Modal
        open={addIpModel}
        onClose={() => setAddIpModel(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyData}
      </Modal>
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Brand Mentions</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : {rating}</b>
              </span>
            </nav>
          </div>
        </div>
      </div>

      {typeof scanCompleted !== "undefined" && !scanCompleted && (
        <div className="card border-success">
          <ScanMessage />
        </div>
      )}

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="body">
              <div className="header">
                <div className="row clearfix">
                  {/* <div className="col-sm-2">
                    <h2>Details</h2>
                  </div> */}
                  <div className="col-sm-3">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      onChange={(event, newValue) => {
                        setApplyCountrySearch(newValue);
                      }}
                      value={applyCountrySearch || []}
                      options={lengthOfLocation || []}
                      getOptionLabel={(option) => option?.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select Country"
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      onChange={(event, newValue) => {
                        setApplyCloudSearch(newValue);
                      }}
                      value={applyCloudSearch || []}
                      options={lengthOfcloudLocations || []}
                      getOptionLabel={(option) =>
                        option?.toString()?.toLowerCase()
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Cloud Provider"
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-3">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-1">
                    {downloadCsvData?.length === 0 ? null : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          exportToCSV(downloadCsvData, "Brand Mention");
                        }}
                        style={{ float: "right" }}
                        href="/add-report"
                        className="btn btn-primary mr-1"
                      >
                        <i
                          className="fa fa-file-excel-o"
                          style={{  }}
                        ></i>
                      </a>
                    )}
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>URL</th>
                        <th>IP Address</th>
                        {/* <th>ASN</th> */}
                        <th>Location</th>
                        <th>Cloud / ISP Provider</th>
                        <th>Open Ports</th>
                        <th>Network Services</th>
                        <th>Operating System</th>
                        <th>Take Down</th>
                        <th>Last Updated on</th>
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        // //console.log(ror,row)
                        let services = [];
                        row?.services?.map((res, index) => {
                          if (services.indexOf(res?.service_name) == -1)
                            services.push(res?.service_name);
                        });
                        return (
                          <tr>
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >
                              <span>{index + 1}</span>
                            </td>
                            <td>
                              <a
                                href={
                                  row?.host?.indexOf("https") == -1
                                    ? `https://${row?.host}`
                                    : `${row?.host}`
                                }
                                target="_blank"
                                style={{  }}
                              >
                                {`${row?.host}`}
                              </a>
                            </td>
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >
                              <div className="d-flex align-items-center">
                                <Tooltip text="Avatar Name" id="client1" />
                                <div
                                  className="ml-3"
                                  style={{
                                     
                                    fontWeight: 400,
                                    fontSize: 14,
                                  }}
                                >
                                  {row?.ip}
                                </div>
                              </div>
                            </td>
                            {/* <td style={{ cursor: 'pointer' }} onClick={() => {
                              setCertModal(true);
                              setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            }}>{row?.autonomous_system?.asn}</td> */}
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >
                              {`${row?.location?.country}`}{" "}
                              {row?.location?.city && row?.location?.city !== ""
                                ? " , "
                                : ""}
                              {`${row?.location?.city}`}
                            </td>
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >{`${
                              row?.autonomous_system?.description ==
                              "GOOGLE-CLOUD-PLATFORM"
                                ? "GOOGLE"
                                : row?.autonomous_system?.description
                            }`}</td>
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >
                              {row?.services?.map((res, index) => {
                                // if (ipData.includes(parseInt(res?.port))) {
                                if (index == 0)
                                  return <span>{res?.port} </span>;
                                else if (index % 6 == 0)
                                  return (
                                    <span>
                                      {" "}
                                      , {res?.port}
                                      <br />
                                    </span>
                                  );
                                else return <span> , {res?.port}</span>;
                                // }
                              })}
                            </td>
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >
                              {services?.map((res, index) => {
                                if (index % 5 == 0)
                                  return (
                                    <>
                                      <br />
                                      <span className="badge badge-warning">
                                        {res}
                                      </span>
                                    </>
                                  );
                                else
                                  return (
                                    <span className="badge badge-warning">
                                      {res}
                                    </span>
                                  );
                              })}
                            </td>
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >
                              {row?.operating_system?.product?.toUpperCase() ||
                                "NA"}
                            </td>
                            <td>
                              {row?.requestStatus && row?.requestStatus != "" ? (
                                row?.requestStatus
                              ) : (
                                <button
                                  className="btn btn-danger btn-sm mr-1"
                                  // to={''}
                                  onClick={() => takeDownValue(row)}
                                >
                                  Request
                                  <br />
                                  Takedown
                                </button>
                              )}
                            </td>
                            <td
                            // style={{ cursor: 'pointer' }} onClick={() => {
                            //   setCertModal(true);
                            //   setCertModalBody(row?.certificates?.[0] || "No Data Found")
                            // }}
                            >
                              {row?.updatedAt
                                ? moment(row?.updatedAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                : row?.createdAt
                                ? moment(row?.createdAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                : "NA"}
                            </td>
                            {/* <td>
                              <button
                                type="button"
                                className="btn btn-sm btn-default"
                                title="Delete"
                                data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                  setIpId(row?._id);
                                  setIpRemoveModal(true);
                                }}
                              >
                                <i className="icon-trash"></i>
                              </button>
                              <div className="dropdown">
                                <a
                                  className=" dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                  style={{ fontSize: "20px", zIndex: "0", padding: "5px 15px", marginRight: "20px" }}
                                >
                                </a>
                                <ul className="dropdown-menu list-dropdown-custom" style={{ left: "-180px !important" }}>
                                  <li style={row.AcceptRisk ? { background: "#c5c4c4" } : {}}>
                                    <i className="fas fa-exclamation-triangle" style={{ fontSize: "16px", padding: "10px" }}>
                                      <span style={{ cursor: "pointer", padding: "10px", fontWeight: "normal" }} >Accept Risk</span>
                                    </i>
                                  </li>
                                  <li style={row.FalsePositive ? { background: "#c5c4c4" } : {}}>
                                    <i className="far fa-check-circle" style={{ fontSize: "16px", padding: "10px" }}>
                                      <span style={{ cursor: "pointer", padding: "10px", fontWeight: "normal" }} >False Positive</span>
                                    </i>
                                  </li>

                                </ul>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {mapData && mapData.length > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={mapData?.length}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-4 col-md-12">
          <div className="card user_statistics">
            <div className="body" style={{ height: "400px" }}>
              <span>Previous scan details</span>
              {scanDetails && (
                <BasicLineColumn
                  dates={scanDetails?.dates}
                  // openPortArray={openPortArray}
                  ipArray={ipArray}
                  color={["#008ffb", "#00e396"]}
                  yAxis="IP Discovered"
                />
              )}
            </div>
          </div>
        </div> */}
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${
          isModal ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg assetsDiscoveryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalHeading}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <section>
                  <div className="row">
                    <div className="col-sm-12">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          exportToCSVNew(modalHeading);
                        }}
                        href="/add-report"
                        style={{ float: "right" }}
                        className="btn btn-primary mr-1"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    </div>
                  </div>
                  <div className="card">
                    {/* <div className="body"> */}
                    <div className="table-responsive">
                      <table className="table table-hover table-custom spacing5">
                        <thead>
                          <tr>
                            {Object.keys(modalBody).map((val) => {
                              return (
                                <th style={{ width: "200" }}>
                                  {val?.replace(/['"]+/g, "")}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.keys(modalBody).map((val) => {
                              let value = modalBody[val];
                              return (
                                <td style={{ verticalAlign: "top" }}>
                                  {value?.map((row) => {
                                    return (
                                      <>
                                        <span>{row}</span>
                                        <br />
                                      </>
                                    );
                                  })}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${
          certModal ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg assetsDiscoveryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Certificate
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setCertModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <section>
                  <div
                    className="card"
                    dangerouslySetInnerHTML={{
                      __html: certModalBody?.replace(/\n/g, "<br />"),
                    }}
                  >
                    {/* {certModalBody}
                    dangerouslySetInnerHTML={{__html: alert.alertContent}} */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade  ${ipRemoveModal ? "d-block show" : ""}`}
        id="exampleModal"
        onClick={() => setIpRemoveModal(false)}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setIpRemoveModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  deleteIp(ipId, id, true, dispatch);
                  setIpRemoveModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade Form-Wizard-modal ${isTakeDownModal ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Website Takedown Request
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setTakeDownModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card card_ck">
                    <div className="body">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={stolenLocation}
                            onChange={(e) => setStolenLocation(e.target.value)}
                            placeholder="Stolen Location"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={contenSource}
                            onChange={(e) => setContenSource(e.target.value)}
                            placeholder="Content Source"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={infringingIp}
                            onChange={(e) => setInfringingIp(e.target.value)}
                            placeholder="Infringing IP"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <label>Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={editorContent}
                          onChange={handleEditorChange}
                          config={{
                            styles: [
                              `
              .ck-editor__editable {
                min-height: 200px; /* Set your desired minimum height */
              }
              p, h1, h2, h3 {
                color: black; /* Set text color to black */
              }
            `,
                            ],
                          }}
                        />
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button
                          className="btn btn-danger btn-sm mr-1 pull-right"
                          // to={''}
                          style={{ marginTop: "10px" }}
                          onClick={() => submitTakeDownValue()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AssetDiscovery;
